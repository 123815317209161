import React,{useState, useContext, useEffect} from 'react';
import {Auth} from 'aws-amplify';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import CourseOptions from './CourseOptions';
import { Button, Card } from 'react-bootstrap';
import PlaceOrder from '../payment/PlaceOrder';
import { ProductContext } from "../../ProductProvider";
import axios from "axios";
//import {useHistory} from 'react-router-dom';
//import { Routes } from '../../routes';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader";
import { feesBreakUpFull, feesBreakUpPhase1, feesBreakUpPhase2 } from '../../ProData';
const config = require('../../config.json');

export default function MyCourses() {
    //const history=useHistory();
    const { loggedUser, generateDate, generateOrderNo, setAlertDetails, alertDetails, alertClose, validateLogin} = useContext(ProductContext);
    const [collapsed, setCollapsed] = useState(true);
    const [orderCollapsed, setOrderCollapsed] = useState(true);
    const [orderDetails, setOrderDetails]=useState({});
    const [enrollCourse, setEnrollCourse]=useState({});
    const [payAmount, setPayAmount] = useState();
    const [feeBreakUp, setFeeBreakUp] =useState([]); 
    const [loaded, setLoaded] = useState(false);
    const [courseOpted, setCourseOpted]=useState('');
    const [currentPayStatus, setCurrentPayStatus] = useState('');
    const [payMsg, setPayMsg] = useState('');

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
            alertClose();
            fetchMyProfile();
        }else{
          validateLogin();
          //history.push(Routes.DashboardOverview.path);
          }
    },[loggedUser])

    const fetchMyProfile=async()=>{
        setLoaded(false);
        try{
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const searchOption="RegistrationID";
            //    await axios.get(`${config.api.invokeURL}/student/profile?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}`, {
            await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}`, {
              headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
              }
              }).then(async(response) => {
           //    console.log("response : ", response.data[0]);
                if(response.data.length>0)
                {
               //     console.log("order status")
                    setCurrentPayStatus(response.data[0].coursePayment);
                    setCourseOpted(response.data[0].selectedCourse);
                    setPayAmount(response.data[0].selectedCourse.fees);
                    setAlertDetails({
                      ...alertDetails,
                      style:"success",
                      open:true,
                      message: "Course details loaded Successfully!"
                  });  
                }
                setLoaded(true);
            }).catch((error)=>{
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);
          })
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
      }
    }

    const handleEnrollRequest=(event, item)=>{
        event.preventDefault();
     //   console.log("Course enrolled : ", item);
       //var titem=item;
       //titem.courseOption="Online";
       //titem.payOption="Full";
       //var tcourse=[...cart];
       //tcourse.push(item);
      // console.log("Item : ", tcourse);
       // setSelectedCourse(tcourse);
        setEnrollCourse(item);
        toggle();
    }

   const processPayAmount=(newPayOption)=>{
     //  event.preventDefault();
        var tenrollCourse=enrollCourse;
        setPayMsg("");
     //   var tcart=[...cart];
     if(currentPayStatus==="Pending" && newPayOption==="Phase2" && tenrollCourse.courseOption==="Online"){
         setPayMsg("You cannot directly Pay Phase 2 without paying Phase 1");
        /* tenrollCourse.payOptions="Full";
         tenrollCourse.courseOption="Online";
         setPayAmount(6600);
         setEnrollCourse(tenrollCourse);*/
      //   console.log("inside phase 2 - Online");

     }else if(currentPayStatus==="Pending" && newPayOption==="Phase2" && tenrollCourse.courseOption==="Correspondence"){
        setPayMsg("You cannot directly Pay Phase 2 without paying Phase 1");
     /*   tenrollCourse.payOptions="Full";
        tenrollCourse.courseOption="Correspondence";
        setPayAmount(7000);
        setEnrollCourse(tenrollCourse);*/
     //   console.log("inside phase 2 - Correspondance");

    }else{
        if(tenrollCourse.courseOption==="Online" && newPayOption==="Full"){
            setPayAmount(6600);
        }else if(tenrollCourse.courseOption==="Online" && newPayOption==="Phase1"){
            setPayAmount(3400);
        }
        else if(tenrollCourse.courseOption==="Online" && newPayOption==="Phase2"){
            setPayAmount(3200);
        }
        if(tenrollCourse.courseOption==="Correspondence" && newPayOption==="Full"){
            setPayAmount(7000);
        }else if(tenrollCourse.courseOption==="Correspondence" && newPayOption==="Phase1"){
            setPayAmount(3600);
        }
        else if(tenrollCourse.courseOption==="Correspondence" && newPayOption==="Phase2"){
            setPayAmount(3400);
        }
    
        var tfeeBreakup=[];
        if(newPayOption==="Full"){
            tfeeBreakup=feesBreakUpFull;
        }else if(newPayOption==="Phase1"){
            tfeeBreakup=feesBreakUpPhase1;

        }else if(newPayOption==="Phase2"){
            tfeeBreakup=feesBreakUpPhase2;
        }
      //  console.log("Enroll course : ", tenrollCourse);
        setFeeBreakUp(tfeeBreakup);
        tenrollCourse.feesOpted=payAmount;
        tenrollCourse.payOptions=newPayOption;
     //   console.log("Enroll course : ", tenrollCourse);
     //  tcart.push(tenrollCourse);
      //  console.log("Final course details : ", tenrollCourse);
    //    setOrderDetails(tenrollCourse);
        setEnrollCourse(tenrollCourse);
    }
    }

    const handlePayOptions=(event, newPayOption)=>{
      event.preventDefault();
    //  console.log("new option : ", newPayOption);
    //  console.log("Enrolled course : ", enrollCourse);
      processPayAmount(newPayOption);
    }

    const handleContinueEvent=async(event)=>{
        event.preventDefault();
        try{
            setAlertDetails({
                ...alertDetails,
                style:"information",
                open:true,
                message: "Processing Order...."
            }); 
            const torderDate=generateDate();
            const orderno=generateOrderNo();
            const params={
                clientID:"VJDepth",
                orderID:orderno,
                orderDate:torderDate,
                orderedBy:loggedUser.nickname,
                orderStatus:"Initiated",
                orderAmount:payAmount,
                paymentMode:"Online",
                discountApplied:"NA",
                discountAmount:0,
                cart:enrollCourse,
                feeBreakUp:feeBreakUp
            };
          //  console.log("Params : ", params);
            setOrderDetails(params);
            await axios.post(`${config.api.invokeURL}/student/order/${orderno}`, params,{
                headers: {
                //  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                 // console.log("response : ", response); 
                  toggle();
                  orderToggle();      
                }).catch((error)=>{
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0); 
                 // setLoaded(true);
                })
    
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);         
        }
    }

    const orderToggle = () => {
        //   console.log("clicked");
             setOrderCollapsed(!orderCollapsed);
         }

    const toggle = () => {
        //   console.log("clicked");
             setCollapsed(!collapsed);
         }

  return (
<>
    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
    </Alert>
    <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>My Course</Breadcrumb.Item>
          </Breadcrumb>
          <h4>My Course</h4>
       
        </div>
        <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
            <ModalHeader toggle={toggle}>
                Payment Options
            </ModalHeader>
            <ModalBody>    
                <CourseOptions payMsg={payMsg} enrollCourse={enrollCourse} setEnrollCourse={setEnrollCourse} payAmount={payAmount}
               processPayAmount={processPayAmount} handlePayOptions={handlePayOptions} currentPayStatus={currentPayStatus}/>
            </ModalBody>
            <ModalFooter>
        <Button disabled={payMsg===""?false:true} onClick={(event)=>{handleContinueEvent(event)}}>
          Place Order
        </Button>
         {' '}
        <Button onClick={toggle}>
          Close
        </Button>
         </ModalFooter>
        </Modal>
        <Modal toggle={orderToggle} isOpen={!orderCollapsed} style={{top:'10%'}}>
            <ModalHeader toggle={orderToggle}>
            <div>Confirm Order & Payment</div>
            </ModalHeader>
            <ModalBody>   
               <PlaceOrder orderDetails={orderDetails}/> 
            </ModalBody>
            <ModalFooter>
       
         {' '}
        <Button onClick={orderToggle}>
          Close
        </Button>
         </ModalFooter>
        </Modal>
    <div>Courses</div>
    <Card>
        <p>Course ID : {courseOpted.courseID}</p>
        <p>Course Name : {courseOpted.courseName}</p>
        <p>Duration : {courseOpted.duration}</p>
        <p>Fees : {courseOpted.fees}</p>
        <p>Preference : {courseOpted.courseOption}</p>
        {courseOpted.payOptions==="Full" && courseOpted.payStatus==="PaidFull"?<p>Payment Completed</p>:<button onClick={(event)=>{handleEnrollRequest(event, courseOpted)}}>Initiate Payment</button>}
    </Card>
    </>
    )
}
