import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Signup from "./auth/Signup";
import {Helmet} from 'react-helmet-async';

//auth
import Signin from "./auth/Signin";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
//import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import { ProductContext } from '../ProductProvider';

//students
import Courses from './student/Courses';
import MyProfile from './student/MyProfile';
import MyPayments from './student/MyPayments';
import MyCourses from './student/MyCourses';

//order
import OrderStatus from './order/OrderStatus';
import WelcomeDash from './WelcomeDash';
import MyMarks from './student/MyMarks';
import EBooks from './student/EBooks';
import Exams from './student/Exams';
import LandingPage from './dashboard/LandingPage';
import CourseJourney from './student/CourseJourney';
import CalendarView from './schedular/CalendarView';
import RequestForm from './inbox/RequestForm';
import MyRequestList from './inbox/MyRequestList';
import OpenNotification from './inbox/OpenNotification';
import DisplayVideos from './videoCourses/DisplayVideos';

const config = require('../config.json');

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const { loggedUser } = useContext(ProductContext);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  /*const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }*/

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />

        <main className="content">
        {loggedUser.isAuthenticated?
          <Navbar />:null}
          <Component {...props} />
         {/*} <Footer toggleSettings={toggleSettings} showSettings={showSettings} />*/}
        </main>
      </>
    )}
    />
  );
};

export default function HomePage() {
  //const history= useHistory();
  //const { fetchOpenItems, alertClose, validateLogin } = useContext(ProductContext);

  /*useEffect(() => {
    alertClose();
    validateLogin();    
   },[]);*/
 
   /*const validateLogin = async() =>{
     try{
        await Auth.currentAuthenticatedUser()
       .then(async(user)=>{
         console.log("user : ", user);
         var tadmin=false;
         if(user.attributes['profile']==="Student"){
         // fetchOpenItems();
         await setLoggedUser({...loggedUser,
          name:user.attributes['name'],
          email:user.attributes['email'],
          profile:user.attributes['profile'],
          DOB:user.attributes['birthdate'],
          website:user.attributes['website'],
          nickname:user.attributes['nickname'],
          gender:user.attributes['gender'],
          family_name:user.attributes['family_name'],
          phone_number:user.attributes['phone_number'],
          isAuthenticated:true,
          isAdmin:tadmin
        });
        const tnickname=user.attributes['nickname'];
        const tImageName=user.attributes['nickname']+".jpeg";
        setMyProfileImage(`${config.s3.profileUrl}${tnickname}${"/"}${tImageName}`);

         }else{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"You are not authorized to access this portal"
        });         
          history.push(Routes.Signin.path);

         }
        // navigate("/");   
         
       })
       .catch((error) =>{
         //console.log("error:", error);
         setLoggedUser({...loggedUser,
           name:'',
           email:'',
          // currentStatus:'',
           DOB:'',
           profile:'',
           //mobile:'',
           website:'',
           nickname:'',
           gender:'',
           family_name:'',
           phone_number:'',
           isAuthenticated:false,
           isAdmin:false
         }); 
         history.push(Routes.Signin.path);
 
       })     
     }catch(error){
      // console.log(error);
     }
   }*/

return(
  <>
  <Helmet>
      <title>Student's Corner - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Student's Corner - VJDepth - Distance Education Programme in Theology. Student portal is to empower students with all digital access to the learning needs. One stop place for VJDepth students to get complete details about their learning. Distance Education Programme in Theology (DEPTh) The Second Vatican Council had a dream when it wrote: “Let the laity, therefore, diligently apply themselves to a more profound knowledge of revealed truth…” (LG, 35). Sharing in the same dream Vidyajyoti has initiated the new Distance Education Programme in Theology (DEPTh) primarily for the lay faithful. It welcomes all who desire to deepen their Christian faith, and search for avenues of deeper participation in the mission of the Church." />
    <meta name="keywords" content="Student's Corner - VJDepth - Distance Education Programme in Theology. Student's portal for all their training needs." /> 
    </Helmet>

  <Switch>
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={WelcomeDash} />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithSidebar exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={LandingPage} />
    {/* pages */}
    <RouteWithSidebar exact path={Routes.InboxNewNotification.path} component={RequestForm} />
    <RouteWithSidebar exact path={Routes.InboxOpenQuery.path} component={MyRequestList} />
    <RouteWithSidebar exact path={Routes.InboxOpenNotification.path} component={OpenNotification} />


    {/* Students */}
    <RouteWithSidebar exact path={Routes.CoursesOffered.path} component={Courses} />
    <RouteWithSidebar exact path={Routes.CourseCalendar.path} component={CalendarView} />
    <RouteWithSidebar exact path={Routes.CourseVideosInfo.path} component={DisplayVideos} />


    <RouteWithSidebar exact path={Routes.MyProfile.path} component={MyProfile} />
    <RouteWithSidebar exact path={Routes.CourseJourney.path} component={CourseJourney} />
    <RouteWithSidebar exact path={Routes.MyPayments.path} component={MyPayments} />
    <RouteWithSidebar exact path={Routes.MyCourses.path} component={MyCourses} />
    <RouteWithSidebar exact path={Routes.MarkSheet.path} component={MyMarks} />
    <RouteWithSidebar exact path={Routes.EBooks.path} component={EBooks} />
    <RouteWithSidebar exact path={Routes.Exams.path} component={Exams} />

    {/* Order */}
    <RouteWithSidebar exact path={Routes.OrderStatus.path} component={OrderStatus} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
  </>
)
}