import React, { useEffect, useContext, useState } from 'react';
import { Col, Row, Card, Form } from 'react-bootstrap';
import {Input, Alert, Table} from 'reactstrap';
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader";
import ProfileImage from '../../components/manageimage/ProfileImage';
import { Auth } from 'aws-amplify';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function MyProfile() {
  //const history=useHistory();
  const { loggedUser, alertDetails, myProfileImage, setAlertDetails, alertClose, validateLogin} = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [profileDetails, setProfileDetails]=useState('');

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      fetchMyProfile();
    }else{
      validateLogin();
    //history.push(Routes.DashboardOverview.path);
    }
  },[loggedUser])

  const fetchMyProfile=async()=>{
    setLoaded(false);
    try{
         const searchOption="RegistrationID";
         const session = await Auth.currentSession();
         const access_token=session.idToken.jwtToken;
            await axios.get(`${config.api.invokeURL}/dashboard/search?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}`, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data[0]);
            if(response.data.length>0)
            {
                setProfileDetails(response.data[0]);
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: "Profile loaded Successfully!"
              });  
              setLoaded(true);

            }
            setLoaded(true);

        }).catch((error)=>{
          //console.log("error : ", error);
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      })
    }catch(error){
      //console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);
  }
}

  return (
    <div>
    <Helmet>
      <title>Profile - Student's Corner - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Profile Details - Student's Corner - VJDepth - Distance Education Programme in Theology. Students can view their payment history." />
      <meta name="keywords" content="Profile Details - Student's Corner - VJDepth - Distance Education Programme in Theology. Student's portal for all their training needs." /> 
    </Helmet>

          <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
    <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>My Profile</Breadcrumb.Item>
          </Breadcrumb>
          <h4>My Profile</h4>
       
        </div>

    <ProfileImage profileImage={myProfileImage} />
    <div style={{textAlign: "center"}}><h6>{profileDetails.title}. {profileDetails.applicantName}</h6></div>
      <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
        <Row>
            <Col md={2} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Control type="text"  disabled defaultValue={profileDetails.title}/>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control  type="text" disabled defaultValue={profileDetails.firstName}/>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.middleName}/>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.lastName}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Date Of Birth</Form.Label>
                <Input type="date" disabled defaultValue={profileDetails.dateOfBirth}
               />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.gender} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="maritalstatus">
                <Form.Label>Marital Status</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.maritialStatus}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="occupation">
                <Form.Label>Occupation</Form.Label>
                <Form.Control  type="text"  disabled defaultValue={profileDetails.occupation}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>Religion</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.religion}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label>Denomination</Form.Label>
                <Form.Control type="text"  disabled defaultValue={profileDetails.denomination}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col sm={6} className="mb-3">
              <Form.Group id="diocese">
                <Form.Label>Diocese</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.diocese}/>
              </Form.Group>
            </Col>
            <Col sm={2} className="mb-3">
              <Form.Group id="areanumber">
                <Form.Label>Mobile</Form.Label>
                <Form.Control  type="text" disabled defaultValue={profileDetails.areaCode} />
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
            <Form.Group id="mnumber">
            <Form.Label>  </Form.Label>
                <Form.Control  type="number" disabled defaultValue={profileDetails.mobileNumber}/>
                 </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" disabled defaultValue={profileDetails.emailID}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Landline Number</Form.Label>
                <Form.Control type="number" disabled defaultValue={profileDetails.landlineNumber}/>
              </Form.Group>
            </Col>
          </Row> 
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.addressStreet} />
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.addressState} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group id="zip">
                <Form.Label>ZIP</Form.Label>
                <Form.Control type="tel" disabled defaultValue={profileDetails.addressPincode}/>
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="nationality">
                <Form.Label>Nationality</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.nationality}/>
              </Form.Group>
            </Col>
            
          </Row>          
          <Row>
          <Col md={6} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label>Mother Tongue</Form.Label>
                <Form.Control type="text" disabled defaultValue={profileDetails.motherTongue}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>English Proficiency</Form.Label>
                <Form.Control type="text"  disabled defaultValue={profileDetails.englishProficiency}  />
              </Form.Group>
            </Col>            
          </Row>
          <Table hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Name of the Institute</th>
      <th>Place of the Institute</th>
      <th>Start Year</th>
      <th>End Year</th>
      <th>Degree</th>
    </tr>
  </thead>
  <tbody>
  {profileDetails.degreeDetails && profileDetails.degreeDetails.map((item, index)=>
    <tr key={index}>
      <th scope="row">{index+1}</th>
      <td>{item.name}</td>
      <td>{item.place}</td>
      <td>{item.startYear}</td>
      <td>{item.endYear}</td>
      <td>{item.degree}</td>
   </tr>)}
  </tbody>
          </Table>
        </Form>
      </Card.Body>
    </Card>
    </div>
  )
}
