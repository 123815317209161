import React, { useEffect, useState, useContext } from 'react';
import { Form, Col, Row, Card, Button} from '@themesberg/react-bootstrap';
//import TimerSample from '../../components/examTimer/TimerSample';
import { ProductContext } from '../../ProductProvider';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import Preloader from "../../components/Preloader";
import {Alert } from "reactstrap";

const config = require('../../config.json');


export default function DescriptiveModal(props) {
    const { loggedUser, alertClose, alertDetails, setAlertDetails, generateDate, handleAssessmentSubmission } = useContext(ProductContext);
    const {questionID, formType, displayQuestions,toggle}= props;
    const [response, setResponse]=useState([]);
    const [initials, setInitials] = useState(false);
    const [formMode, setFormMode] = useState("");
    const [questionDetails, setQuestionDetails] = useState({questionID:"", questionTitle:"", answer:""});
    const [loaded, setLoaded] = useState(false);
    const [questionView, setQuestionView]= useState([]);
    const [disabledValue, setDisabledValue]= useState(true);

    useEffect(()=>{
        alertClose();
        //console.log("Sample Questions : ", questionID);
        setResponse(questionID.assessmentQuestions);
        setQuestionView(displayQuestions);
        //console.log("Questions : ", displayQuestions);
        if(formType==="Response View"){
          setDisabledValue(true);
        }else{
          setDisabledValue(false);
        }
        setInitials(true);
        setLoaded(true);
    },[])

    const handleSubmit=async(event)=>{
        event.preventDefault();
        try{
          setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          //const clientID="VJDEPTH";
          const registrationno=loggedUser.nickname;
          await axios.get(`${config.api.invokeURL}/dashboard/scores/${registrationno}`,{
              headers: {
                Authorization: access_token,
                'x-api-key': config.api.key
              }}, 
          ).then(async(res)=>{
              //console.log("Res : ", res);
              const todayDate=generateDate();
              const tassessmentID=questionID.questionID+"#"+loggedUser.nickname;
              const params={
                assessmentID:tassessmentID,
                assessmentStatus:'Pending Evaluation',
                assessmentResponse:response,
                updatedBy:loggedUser.nickname,
                updatedOn:todayDate
            }
//            console.log("Params :", params);
            await handleAssessmentSubmission(params);
            toggle();
              setLoaded(true);
          }).catch((error)=>{
              //console.log(error);
              setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
          })    
        

        }catch(error){

        }
    }

    const handleRespnse=(event, item)=>{
        var tempResponse=[...response];
        const tindex= tempResponse.find(titem => titem.questionID === item.questionID)
        const index =tempResponse.indexOf(tindex);
         tempResponse[index].response=event.target.value;
         setResponse(tempResponse);
       
    }

    const  handleCheckProcess=(event, item)=>{
        event.preventDefault();
       // setExamDetails(item);
      //  console.log("Item : ", item);
        setQuestionDetails(item);
        setFormMode("Update");
    }

    const handleUpdate=async(event)=>{
        event.preventDefault();
        try{
            var updatedQuestions="";
            var updatedQA="";
            if(formMode==="Add"){
                updatedQuestions= processAddQuestionDetails();
                updatedQA=processAddQADetails();
            }else{
                updatedQuestions= processQuestionDetails();
                updatedQA=processQADetails();
            }
            const todayDate=generateDate();
            const params={
                courseCode:questionID.courseCode,
                questionID:questionID.questionID,
                assessmentQuestions:updatedQuestions,
                assessmentAnswers:updatedQA,
                updatedBy:loggedUser.nickname,
                updatedOn:todayDate,
                category:"UpdateQuestion"
            }
           // console.log("Params : ", params);
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            await axios.patch(`${config.api.invokeURL}/dashboard/exam/newquestionsheet`, params,{
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                  setQuestionDetails({questionID:"", questionTitle:"", answer:""});
                  setAlertDetails({
                      ...alertDetails,
                      style:"success",
                      open:true,
                      message: "Successfully Updated"
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);            
                })
                .catch((error)=>{
                //  console.log("Error :", error);
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0);
                  setLoaded(true);
                })
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);
        }
    }

    const processAddQuestionDetails=()=>{
          const tempResponse={};
          const tempAssessment=questionID.assessmentQuestions;
          tempResponse.questionID=questionDetails.questionID;
          tempResponse.questionTitle=questionDetails.questionTitle;
        //  tempResponse.answer=questionDetails.answer;
          tempAssessment.push(tempResponse);
         // console.log("Exam Details : ", tempAssessment);
          // questionResponse=tempResponse[index];
         //  setAssessmentDetails(tempResponse);
           return tempAssessment; 
     }

     const processAddQADetails=()=>{
        const tempResponse={};
        const tempAssessment=questionID.assessmentAnswers;
        tempResponse.questionID=questionDetails.questionID;
        tempResponse.questionTitle=questionDetails.questionTitle;
        tempResponse.keys=questionDetails.answer;
        tempAssessment.push(tempResponse);
     //   console.log("Question Details : ", tempAssessment);
        // questionResponse=tempResponse[index];
       //  setAssessmentDetails(tempResponse);
         return tempAssessment;
   }
 

    const processQuestionDetails=()=>{
       // console.log("Exam Details : ", examDetails);
       // const tempResponse="";
        var tempResponse=[...questionID.assessmentQuestions];
       //  console.log("Temp Response : ", tempResponse);
         const tindex= tempResponse.find(titem => titem.questionID === questionDetails.questionID)
         const index =tempResponse.indexOf(tindex);
      //   console.log("Temp Response : ", tempResponse[index]);
         tempResponse[index].questionTitle=questionDetails.questionTitle;
         // questionResponse=tempResponse[index];
        //  setAssessmentDetails(tempResponse);
          return tempResponse;
    }

    const processQADetails=()=>{
         var tempResponse=[...questionID.assessmentAnswers];
          const tindex= tempResponse.find(titem => titem.questionID === questionDetails.questionID)
          const index =tempResponse.indexOf(tindex);
          tempResponse[index].questionTitle=questionDetails.questionTitle;
          tempResponse[index].keys=questionDetails.answer;
          return tempResponse;
    }
 

    const handleAdd=(event)=>{
        event.preventDefault();
        const newID=questionID.assessmentQuestions.length+1;
      //  console.log("New ID : ", newID);
        setQuestionDetails({...questionDetails, questionID:newID, questionTitle:"",answer:""});
        setFormMode("Add");
    }
   

  return (
    <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        <div className='mb-5 '>
          <h4>Descriptive Type Exam</h4>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        {formType==="Edit"? <Button active variant="outline-primary" size="sm" onClick={(event)=>{handleAdd(event)}}>Add</Button>
        :null}
        <div className="d-flex">
        {/*<ExamTimer />*/}
        {/*formType==="Exam" && questionView.length>0 ? <TimerSample />:null*/}
        </div>
        </div>

    {initials && questionView.map((item, index)=>
    <div className='container' key={index}>
    <Row className='card p-3'>
            <Col className='pt-2' scope="row" >
            {formType==="Edit" && questionView.length>0?  <th scope="row">
            <Form.Check type="radio" name="test" value={item.questionID} onClick={(event)=>handleCheckProcess(event, item)}/>
            </th>:<span>Q.{index+1}</span>}
             <span className='p-2'>  {item.questionTitle}  </span>
            </Col>
            {formType!=="Response View"?
            <Col className='pt-2'>
            <Form.Control as="textarea" rows={3} name={"Q"+item.questionID} onChange={event=>handleRespnse(event,item)}/>
            </Col>:<Col className='pt-2'>
            <Form.Control as="textarea" rows={3} name={"Q"+item.questionID} disabled={disabledValue} value={item.response}/>
            </Col>}
            </Row>
            </div>
    )}
    {formType==="Edit"?<div>
        <Row>
        <Col xs={12} xl={12}>
        <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <p className="mb-4">{formMode} Question Details</p>
        <Form>
        <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="coursetitle">
                <Form.Label>Question No</Form.Label>
                <Form.Control required type="text" value={questionDetails.questionID}
                disabled/>
              </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="phaseno">
                <Form.Label>Question Title</Form.Label>
                <Form.Control type="text" value={questionDetails.questionTitle}
                onChange={(event)=>setQuestionDetails({...questionDetails, questionTitle:event.target.value})} />
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
          <Col md={6} className="mb-3">
              <Form.Group id="phaseno">
                <Form.Label>Answer</Form.Label>
                <Form.Control as="textarea" rows={3} name={"Q"} 
                value={questionDetails.answer}
                onChange={(event)=>setQuestionDetails({...questionDetails, answer:event.target.value})}/>
              </Form.Group>
            </Col>
          </Row>
          <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleUpdate(event)}}>{formMode==="Add"?"Add":"Update"}</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
        </Col>

            </Row>
            </div>:null} 

    {formType==="Exam" && questionView.length>0 ?<div className='text-center'>
        <button className='btn-success rounded text-center' onClick={(event)=>{handleSubmit(event)}}>Submit</button> 
    </div>:null}
        </div>
    </>
  )
}
