import React,{useState, useContext, useEffect} from 'react';
//import {coursesOffered} from '../../ProData';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
//import { faSearch, faEye, faEdit, faDownload, faFileDownload, faCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CourseOptions from './CourseOptions';
import { Button, Card } from 'react-bootstrap';
import PlaceOrder from '../payment/PlaceOrder';
import { ProductContext } from "../../ProductProvider";
import axios from "axios";
//import {useHistory} from 'react-router-dom';
//import { Routes } from '../../routes';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader";
import { feesBreakUpFull, feesBreakUpPhase1, feesBreakUpPhase2 } from '../../ProData';
const config = require('../../config.json');

export default function Courses() {
    //const history=useHistory();
    const { loggedUser, generateDate, generateOrderNo, setAlertDetails, alertDetails, alertClose, validateLogin} = useContext(ProductContext);
    const [collapsed, setCollapsed] = useState(true);
    const [orderCollapsed, setOrderCollapsed] = useState(true);
    const [orderDetails, setOrderDetails]=useState({});
    const [enrollCourse, setEnrollCourse]=useState({});
    const [payAmount, setPayAmount] = useState();
    const [feeBreakUp, setFeeBreakUp] =useState([]); 
    const [loaded, setLoaded] = useState(false);
    const [courseOpted, setCourseOpted]=useState('');

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
            alertClose();
            fetchMyProfile();
        }else{
            validateLogin();
          //history.push(Routes.DashboardOverview.path);
          }
    },[loggedUser])

    const fetchMyProfile=async()=>{
        setLoaded(false);
        try{
            //const torderno=orderno.toString();
         //   await axios.get(`${config.api.invokeURL}/student/order/${orderno}`, {
             const searchOption="MyProfile";
                await axios.get(`${config.api.invokeURL}/student/profile?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}`, {
    
                headers: {
               //   Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
               //console.log("response : ", response.data[0]);
                if(response.data.length>0)
                {
               //     console.log("order status")
                    setCourseOpted(response.data[0].selectedCourse);
                    setAlertDetails({
                      ...alertDetails,
                      style:"success",
                      open:true,
                      message: "Course details loaded Successfully!"
                  });  
    
                }
                setLoaded(true);
            }).catch((error)=>{
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);
          })
    
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
    
      }
    }

    const handleEnrollRequest=(event, item)=>{
        event.preventDefault();
      //  console.log("Course enrolled : ", item);
       //var titem=item;
       //titem.courseOption="Online";
       //titem.payOption="Full";
       //var tcourse=[...cart];
       //tcourse.push(item);
      // console.log("Item : ", tcourse);
       // setSelectedCourse(tcourse);
        setEnrollCourse(item);
        toggle();
    }

   const processPayAmount=()=>{
     //  event.preventDefault();
        var tenrollCourse=enrollCourse;
     //   var tcart=[...cart];
        if(enrollCourse.courseOption==="Online" && enrollCourse.payOption==="Full"){
            setPayAmount(6000);
        }else if(enrollCourse.courseOption==="Online" && enrollCourse.payOption==="Phase1"){
            setPayAmount(3100);
        }
        else if(enrollCourse.courseOption==="Online" && enrollCourse.payOption==="Phase2"){
            setPayAmount(2900);
        }if(enrollCourse.courseOption==="Correspondence" && enrollCourse.payOption==="Full"){
            setPayAmount(6000);
        }else if(enrollCourse.courseOption==="Correspondence" && enrollCourse.payOption==="Phase1"){
            setPayAmount(3100);
        }
        else if(enrollCourse.courseOption==="Correspondence" && enrollCourse.payOption==="Phase2"){
            setPayAmount(2900);
        }
        var tfeeBreakup=[];
        if(enrollCourse.payOption==="Full"){
            tfeeBreakup=feesBreakUpFull;
        }else if(enrollCourse.payOption==="Phase1"){
            tfeeBreakup=feesBreakUpPhase1;

        }else if(enrollCourse.payOption==="Phase2"){
            tfeeBreakup=feesBreakUpPhase2;
        }
    //    console.log("fees break up : ", tfeeBreakup);
        setFeeBreakUp(tfeeBreakup);
        tenrollCourse.feesOpted=payAmount;
     //  tcart.push(tenrollCourse);
      //  console.log("Final course details : ", tenrollCourse);
    //    setOrderDetails(tenrollCourse);
        setEnrollCourse(tenrollCourse);

    }

    const handleContinueEvent=async(event)=>{
        event.preventDefault();
        try{
            setAlertDetails({
                ...alertDetails,
                style:"information",
                open:true,
                message: "Processing Order...."
            }); 
            const torderDate=generateDate();
            const orderno=generateOrderNo();
            const params={
                clientID:"VJDepth",
                orderID:orderno,
                orderDate:torderDate,
                orderedBy:loggedUser.nickname,
                orderStatus:"Initiated",
                orderAmount:payAmount,
                paymentMode:"Online",
                discountApplied:"NA",
                discountAmount:0,
                cart:enrollCourse,
                feeBreakUp:feeBreakUp
            };
          //  console.log("Params : ", params);
            setOrderDetails(params);
            await axios.post(`${config.api.invokeURL}/student/order/${orderno}`, params,{
                headers: {
                //  Authorization: access_token,
                  'x-api-key':config.api.key
                }})
                .then(async(response)=>{
                 // console.log("response : ", response); 
                  toggle();
                  orderToggle();      
                }).catch((error)=>{
                    setAlertDetails({
                      ...alertDetails,
                      style:"warning",
                      open:true,
                      message: "Error Occured : " + error.message
                  }); 
                  window.scrollTo(0,0); 
                 // setLoaded(true);
                })
    
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);         
        }
    }


    const orderToggle = () => {
        //   console.log("clicked");
             setOrderCollapsed(!orderCollapsed);
         }

    const toggle = () => {
        //   console.log("clicked");
             setCollapsed(!collapsed);
         }

  return (
      <>
                <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
    <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>My Course</Breadcrumb.Item>
          </Breadcrumb>
          <h4>My Course</h4>
       
        </div>

        <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
            <ModalHeader toggle={toggle}>
                Payment Options
            </ModalHeader>
            <ModalBody>    
                <CourseOptions enrollCourse={enrollCourse} setEnrollCourse={setEnrollCourse} payAmount={payAmount}
               processPayAmount={processPayAmount}/>

            </ModalBody>
            <ModalFooter>
        <Button onClick={(event)=>{handleContinueEvent(event)}}>
          Place Order
        </Button>
         {' '}
        <Button onClick={toggle}>
          Close
        </Button>
         </ModalFooter>
        </Modal>
        <Modal toggle={orderToggle} isOpen={!orderCollapsed} style={{top:'10%'}}>
            <ModalHeader toggle={orderToggle}>
            <div>Confirm Order & Payment</div>
            </ModalHeader>
            <ModalBody>   
               <PlaceOrder orderDetails={orderDetails}/> 
            </ModalBody>
            <ModalFooter>
       
         {' '}
        <Button onClick={orderToggle}>
          Close
        </Button>
         </ModalFooter>
        </Modal>


    <div>Courses</div>
    <Card>
        <p>Course ID : {courseOpted.courseID}</p>
        <p>Course Name : {courseOpted.courseName}</p>
        <p>Duration : {courseOpted.duration}</p>
        <p>Fees : {courseOpted.fees}</p>
        <p>Preference : {courseOpted.courseOption}</p>
        {courseOpted.payOptions==="Full" && courseOpted==="Paid"?<p>Payment Completed</p>:<button onClick={(event)=>{handleEnrollRequest(event, courseOpted)}}>Initiate Payment</button>}

    </Card>

   {/*} <Table hover responsive>
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Course No
                </th>
                <th>
                    Course Name
                </th>
                <th>
                    Duration
                    </th>
                    <th>Fees</th>

                </tr>
            </thead>
            <tbody>
            {coursesOffered.map((item, index)=>

            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.courseID}
            </td>
            <td>
                {item.courseName}
            </td>
            <td>
                {item.duration}
            </td>
            <td>
                {item.fees}
            </td>
            <td>
            
            <button onClick={(event)=>{handleEnrollRequest(event, item)}}>Enroll</button>
           {/*} <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
            <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
          {/*}  </td>
            </tr>)}
            </tbody>
        </Table>*/}
    </>
    )
}
