import React, {useState} from 'react';
import { Routes } from './routes';
import {useHistory} from 'react-router-dom';
import { Auth } from 'aws-amplify';
import axios from "axios";

const ProductContext = React.createContext();
const config = require('./config.json');

const ProductProvider = (props) => {
let history=useHistory();
const [loggedUser, setLoggedUser] = useState({
    name: 'Guest', email: '', website:'', profile:'Guest', DOB:'', isAuthenticated:false, mobile:'', nickname:'',gender:'',family_name:'',phone_number:'',});
const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
const [myProfileImage, setMyProfileImage] = useState('defaultAvatar.jpg');
const [cart, setCart] = useState([]);
const [cartSubTotal, setCartSubTotal]=useState(0);
const [cartTax, setCartTax]=useState(0);
const [cartTotal, setCartTotal]= useState(0);
const [enrollCourse, setEnrollCourse]=useState({});
//const [courses, setCourses]= useState(coursesOffered);
const [payAmount, setPayAmount] = useState(15000);
const [openNotifications, setOpenNotifications] = useState([]);


const processPayAmount=()=>{
    console.log("selected Course : ", enrollCourse);
    var tenrollCourse=enrollCourse;
    var tcart=[...cart];
    if(enrollCourse.courseOption==="Online" && enrollCourse.payOption==="Full"){
        setPayAmount(15000);
    }else if(enrollCourse.courseOption==="Online" && enrollCourse.payOption==="Phase1"){
        setPayAmount(7000);
    }
    else if(enrollCourse.courseOption==="Online" && enrollCourse.payOption==="Phase2"){
        setPayAmount(8000);
    }if(enrollCourse.courseOption==="Correspondence" && enrollCourse.payOption==="Full"){
        setPayAmount(17000);
    }else if(enrollCourse.courseOption==="Correspondence" && enrollCourse.payOption==="Phase1"){
        setPayAmount(8000);
    }
    else if(enrollCourse.courseOption==="Correspondence" && enrollCourse.payOption==="Phase2"){
        setPayAmount(9000);
    }
    tenrollCourse.fees=payAmount;
    tcart.push(tenrollCourse);
    console.log("TCART : ", tcart);
    setCart([...tcart]);
  //  setEnrollCourse({...enrollCourse,fees:payAmount});
   // index = tempProducts.indexOf(getItem(enrollCourse.courseID));
   // console.log("Index : ", index);
   // product = tempProducts[index];
   // product.inCart = true;
   // product.courseOption = enrollCourse.courseOption;
   // product.payOption=enrollCourse.payOption;
   // const price = parseFloat(product.courseAmount);
   // product.total = price;
    //console.log("Product : ", product);
    //const tempCart=[...cart];
    //tempCart.push(product);
  //  setCart([...tempCart]);
  //  addTotals(tempCart);
  //  setProducts();
}

const alertClose=()=>{
    setAlertDetails({
        ...alertDetails,
        style:"",
        open:false,
        message:""
    });
}

const handleImageError=(e)=>{
    // console.log("Event : ", e);
    e.target.src = "https://s3.ap-south-1.amazonaws.com/dev.idntica.com/img/gallery/defaultAvatar.jpg"
}

const generateDate=()=>{
    const current_datetime = new Date();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,"-",month_value,"-", date_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    return accountNo;
    //console.log("acc : ", accountNo);
  
}

const generateOrderNo=()=>{
    const current_datetime = new Date();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    return accountNo;
    //console.log("acc : ", accountNo);
  
}

const fetchOpenNotifications =async()=>{
  try {
      //setLoaded(false);
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const searchOption="OpenNotification";
          const searchValue="Notification";
          const res = await axios.get(`${config.api.invokeURL}/inbox?searchValue=${searchValue}&searchOption=${searchOption}`,{
              headers:{
                  Authorization: access_token,
                  'x-api-key': config.api.key
              }
          });
          setOpenNotifications(res.data);
          //console.log("res DAta : ", res);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: res.data.length + " - Queries found"
          }); 
          window.scrollTo(0,0); 
         /* var i=0;
          while(i<res.data.length){
          //  console.log("before value : ", res.data[i].Book_Classification_No);
           const tempClassValue= [...bookClassification].filter(searchresult =>searchresult.value===res.data[i].Book_Classification_No);
           res.data[i].Book_Classification_No=tempClassValue[0].name;
           //console.log("temp class vale : ", tempClassValue);
           //console.log("After update : ", res.data[i].Book_Classification_No);

           i++;
          }*/
         // setLoaded(true);
          //hideLoader();
      
  } catch (error) {
      //setLoaded(true);
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      window.scrollTo(0,0); 
  }
}

const validateLogin = async() =>{
    try{
       await Auth.currentAuthenticatedUser()
      .then(async(user)=>{
        //console.log("user : ", user);
        var tadmin=false;
        if(user.attributes['profile']==="Student"||user.attributes['profile']==="Admin"){
          fetchOpenNotifications();
        await setLoggedUser({...loggedUser,
         name:user.attributes['name'],
         email:user.attributes['email'],
         profile:user.attributes['profile'],
         DOB:user.attributes['birthdate'],
         website:user.attributes['website'],
         nickname:user.attributes['nickname'],
         gender:user.attributes['gender'],
         family_name:user.attributes['family_name'],
         phone_number:user.attributes['phone_number'],
         isAuthenticated:true,
         isAdmin:tadmin
       });
       const tnickname=user.attributes['locale'];
       const tImageName=user.attributes['locale']+".jpeg";
       setMyProfileImage(`${config.s3.profileUrl}${tnickname}${"/"}${tImageName}`);
       history.push(Routes.DashboardOverview.path);

        }else{
         setAlertDetails({
           ...alertDetails,
           style:"warning",
           open:true,
           message:"You are not authorized to access this portal"
       });         
         history.push(Routes.Signin.path);

        }
       // navigate("/");   
        
      })
      .catch((error) =>{
        //console.log("error:", error);
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
         // currentStatus:'',
          DOB:'',
          profile:'',
          //mobile:'',
          website:'',
          nickname:'',
          gender:'',
          family_name:'',
          phone_number:'',
          isAuthenticated:false,
          isAdmin:false
        }); 
        history.push(Routes.Signin.path);

      })     
    }catch(error){
     // console.log(error);
     setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message:"Error Occured : " + error.message
  });         
    history.push(Routes.Signin.path);

    }
}

const handleProcessAssessment=async(params)=>{
    try{
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
          console.log("Params : ", params);
          const registrationno=params.registrationNo;
          await axios.post(`${config.api.invokeURL}/dashboard/scores/${registrationno}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
              console.log("Response : ", response);
            //fetchApplicationDetails();
            setAlertDetails({
                ...alertDetails,
                style:"success",
                open:true,
                message: "Successfully Processed"
            }); 
            window.scrollTo(0,0);
            //courseToggle();
            //handleSearch();
            })
            .catch((error)=>{
              console.log("Error :", error);
                setAlertDetails({
                  ...alertDetails,
                  style:"warning",
                  open:true,
                  message: "Error Occured : " + error.message
              }); 
              window.scrollTo(0,0);
            })
          }catch(error){
            console.log("Error :", error);
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
      }
}

const handleAssessmentSubmission=async(params)=>{
  try{
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
        //console.log("Params : ", params);
        //const registrationno=params.registrationNo;
        await axios.patch(`${config.api.invokeURL}/dashboard/scores/assessmentsubmission`, params,{
          headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
          }})
          .then(async(response)=>{
            //console.log("Response : ", response);
          //fetchApplicationDetails();
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Successfully Processed"
          }); 
          window.scrollTo(0,0);
          //courseToggle();
          //handleSearch();
          })
          .catch((error)=>{
            console.log("Error :", error);
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
          })
        }catch(error){
          console.log("Error :", error);
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message: "Error Occured : " + error.message
        }); 
        window.scrollTo(0,0);
    }
}


    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            alertDetails,
            setAlertDetails,
            alertClose,
            handleImageError, 
            generateDate,
            myProfileImage,
            setMyProfileImage,
            generateOrderNo,
            processPayAmount,
            cart,
            setCart,
            cartSubTotal, 
            setCartSubTotal,
            cartTax,
            setCartTax,
            cartTotal,
            setCartTotal,
            enrollCourse,
            setEnrollCourse,
            payAmount,
            setPayAmount,
            validateLogin,
            handleProcessAssessment,
            handleAssessmentSubmission,
            openNotifications,
            setOpenNotifications
        }}>
        {props.children}
        </ProductContext.Provider>
    )
}

export {ProductProvider, ProductContext};
