
export const Routes = {
    // pages
    DashboardOverview: { path: "/" },
    // auth
    Signin: { path: "/dashboard/sign-in" },
    Signup: { path: "/dashboard/sign-up" },
    ForgotPassword: { path: "/dashboard/forgot-password" },
    ResetPassword: { path: "/dashboard/reset-password" },
    Lock: { path: "/dashboard/lock" },
    NotFound: { path: "/dashboard/404" },
    ServerError: { path: "/dashboard/500" },

    //inbox
    InboxNewNotification:{path:"/inbox/query/new"},
    InboxOpenQuery:{path:"/inbox/my-queries"},
    InboxOpenNotification:{path:"/inbox/notifications"},

    //Students
    CoursesOffered: { path: "/courses-offered" },
    CourseCalendar: { path: "/course-calendar" },
    CourseVideosInfo: { path: "/CourseVideosInfo" },

    MyProfile: { path: "/dashboard/my-profile" },
    MyPayments: { path: "/dashboard/my-payments" },
    MyCourses: { path: "/dashboard/my-courses" },
    MarkSheet: { path: "/dashboard/my-marks" },
    EBooks: { path: "/dashboard/ebooks" },
    Exams: { path: "/dashboard/exams" },
    CourseJourney:{path: "/dashboard/my-journey"},


    //Order
    OrderStatus:{path: "/studentcorner/order/orderstatus"}
};