import React from 'react';
import { Row, Col, Table} from 'react-bootstrap';
import PayTM from './PayTM';
//import GPay from '../googlePay/GPay';
export default function PlaceOrder(props) {
  const {orderDetails}=props;
  const {cart}=props.orderDetails;
  const {feeBreakUp}=props.orderDetails;
  return (
    <>
    <div className='placeorder'> <h5>Confirm Order & Payment</h5>
    <Row>
      <Col > <h5>Order ID </h5> {orderDetails.orderID}
      </Col>
     
      <Col > <h5>Date </h5> {orderDetails.orderDate}
      </Col>
    </Row>
    <Row>
      <Col > <h5>Course ID </h5> {cart.courseID}
      </Col>
      
      <Col > <h5>Course </h5> {cart.courseName}
      </Col>
    </Row>
    <Row>
      <Col > <h5>Duration  </h5> {cart.duration}
      </Col>
      <Col> <h5>Total Fees </h5> {cart.fees}
      </Col>
    </Row>
    <Row>
      <Col > <h5>Class Category </h5> {cart.courseOption}
      </Col>
      <Col > <h5>Payment Option </h5> {cart.payOption}
      </Col>
    </Row>
    <Table hover responsive>
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    Description
                </th>
                <th>
                    Fees
                </th>

                </tr>
            </thead>
            <tbody>
            {feeBreakUp.map((item, index)=>

            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.feeCategory}
            </td>
            <td>
                {item.amount}
            </td>
            </tr>)}
            </tbody>
            </Table>
    <Row>
      <Col> <h5>Amount to be Paid : {orderDetails.orderAmount}</h5> 
      </Col>
    </Row>

<Row className='text-center'>
  <Col className='mb-2'  md={6}><PayTM orderDetails={orderDetails}/>
  </Col>
  {/*<Col  md={6}>
    <GPay />
            </Col>*/}
</Row>
</div>  
    
    </>
  )
}
