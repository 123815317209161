import React, { useEffect, useContext, useState } from 'react';
import { ProductContext } from '../../ProductProvider';
import axios from "axios";
import { Table , Alert } from "reactstrap";
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader";
//import { Routes } from '../../routes';
//import {useHistory} from 'react-router-dom';
import {Auth} from 'aws-amplify';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function MyPayments() {
  //const history=useHistory();
  const { loggedUser, alertDetails, setAlertDetails, alertClose, validateLogin} = useContext(ProductContext);
  const [OrderDetails, setOrderDetails]=useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      fetchOrderDetails()
      }else{
        validateLogin();
    //history.push(Routes.DashboardOverview.path);
    }
  },[loggedUser])

  const fetchOrderDetails=async()=>{
    setLoaded(false);
    try{
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
         const searchOption="MyOrders";
            await axios.get(`${config.api.invokeURL}/student/order?fetchKey=${loggedUser.nickname}&fetchOption=${searchOption}`, {
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response);
            if(response.data.length>0)
            {
           //     console.log("order status")
                setOrderDetails(response.data);
            }
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.data.length + " - Payment Records Found"
          });  
          setLoaded(true);
        }).catch((error)=>{
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        });        
        setLoaded(true);
      })

    }catch(error){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    setLoaded(true);

  }
}
  return (
    <>
    <Helmet>
      <title>Payments - Student's Corner - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Payments History - Student's Corner - VJDepth - Distance Education Programme in Theology. Students can view their payment history." />
      <meta name="keywords" content="Payments History - Student's Corner - VJDepth - Distance Education Programme in Theology. Student's portal for all their training needs." /> 
    </Helmet>

    <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
    <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>My Payments</Breadcrumb.Item>
          </Breadcrumb>
          <h4>My Payments</h4>
       
        </div>
    <Table hover responsive>
             <thead>
                <tr>
                <th>
                    #
                </th>
                <th>
                    order No
                </th>
                <th>
                    Date
                </th>
                <th>
                    Status
                    </th>
                    <th>Amount</th>
                </tr>
            </thead>
            <tbody>
            {OrderDetails.map((item, index)=>
            <tr key={index}>
            <th scope="row">
                {index+1}
            </th>
            <td>
                {item.orderID}
            </td>
            <td>
                {item.orderDate}
            </td>
            <td>
                {item.orderStatus==="TXN_SUCCESS"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Success</p>
                :<p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Failed</p>}
            </td>
            <td>
                {item.orderAmount}
            </td>
            <td>
            
           {/*} <button onClick={(event)=>{handleEnrollRequest(event, item)}}>Enroll</button>
            <button onClick={(event)=>{handleProjectDelete(event, item)}}>Delete</button>
            <button onClick={(event)=>{handleProjectEdit(event, item)}}>Edit</button>*/}
            </td>
            </tr>)}
            </tbody>
            </Table>
    </>
  )
}
