import React, { useEffect, useState, useContext } from 'react'
import axios from "axios";
import OrderDetails from './OrderDetails';
import { ProductContext } from '../../ProductProvider';
import { Alert } from "reactstrap";
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Preloader from "../../components/Preloader";

const config = require('../../config.json');

export default function OrderStatus() {
    const { alertDetails, setAlertDetails, alertClose} = useContext(ProductContext);

    const [fetchedOrder, setFetchedOrder]= useState([]);
    const [loaded, setLoaded]=useState(false);
    const [cartDetails, setCartDetails]=useState('');
    const [feeDetails, setFeeDetails] = useState([]);

    useEffect(()=>{
        setLoaded(false);
        var currentSearchString = window.location.href;
       // console.log("current : ", currentSearchString);
        var tempOrder = currentSearchString.substring(currentSearchString.search("=")+1,currentSearchString.length);
        //console.log("order no : ", tempOrder);
        fetchOrderDetails(tempOrder);
            // setLoaded(true);
    },[])

    const fetchOrderDetails=async(orderno)=>{
        setLoaded(false);
        try{
            const torderno=orderno.toString();
         //   await axios.get(`${config.api.invokeURL}/student/order/${orderno}`, {
             const searchOption="OrderNo";
                await axios.get(`${config.api.invokeURL}/student/order?fetchKey=${orderno}&fetchOption=${searchOption}`, {

                headers: {
               //   Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
               console.log("response : ", response.data[0]);
                if(response.data.length>0)
                {
                    setFetchedOrder(response.data[0]);
                    setCartDetails(response.data[0].cart);
                    setFeeDetails(response.data[0].feeBreakUp)
                    setAlertDetails({
                        ...alertDetails,
                        style:"success",
                        open:true,
                        message: "Order Processed Successfully!"
                    });  
                }
                setLoaded(true);

            }).catch((error)=>{
                setAlertDetails({
                    ...alertDetails,
                    style:"warning",
                    open:true,
                    message:"Error Occured - " + error.message
                });        
                setLoaded(true);            
            })

        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);        }
    }

  return (
      <>
          <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>
    <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>My Orders</Breadcrumb.Item>
          </Breadcrumb>x
        </div>
    {loaded?<OrderDetails fetchedOrder={fetchedOrder} cartDetails={cartDetails} feeDetails={feeDetails}
    loaded={loaded}/>:<div>Processing your order...</div>}
    </>
  )
}
