import React from 'react';
import { Col, Row, Form, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faLocationPin, faPeopleGroup, faPersonDress } from '@fortawesome/free-solid-svg-icons';

export default function EventView(props) {
    const {eventItem}=props;

  return (
      <>
      <Row>
      <Col md={6} className="mb-3">
      <Form.Label>Event Type : {eventItem.title} </Form.Label>
      </Col>
      <Col md={6} className="mb-3">
      <Form.Label> 
        Audience : <FontAwesomeIcon icon={faPeopleGroup} className="icon-dark" />   Batch - {eventItem.audience} </Form.Label>
      </Col>
      </Row>
      <Row>
      <Form.Label>Start Date & Time : <FontAwesomeIcon icon={faCalendarCheck} className="icon-dark" />   {(eventItem.startFrom).toUTCString()} </Form.Label>
      </Row>
      <Row>
      <Form.Label>End Date & Time : <FontAwesomeIcon icon={faCalendarCheck} className="icon-dark" />   {(eventItem.endTo).toUTCString()} </Form.Label>
      </Row>
      <Row>
      <Col md={6} className="mb-3">

      <Form.Label>Presenter : <FontAwesomeIcon icon={faPersonDress} className="icon-dark" /> {eventItem.presenter} </Form.Label>
      </Col>
      <Col md={6} className="mb-3">

      <Form.Label>Location : <FontAwesomeIcon icon={faLocationPin} className="icon-dark" /> {eventItem.location} </Form.Label>
      </Col>
      </Row>
      <Row>
      <Form.Label>Comments : {eventItem.comments} </Form.Label>

      </Row>

      </>
  )
}
