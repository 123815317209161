import React, {useState, useContext, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faDownload, faHome } from '@fortawesome/free-solid-svg-icons';
import { Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import { Auth } from "aws-amplify";
import axios from 'axios';
import {Alert  } from "reactstrap";
import Preloader from "../../components/Preloader";
import { courseMaterialData } from "../../ProData";
import useFileDownloader from "../../components/downloader/useFileDownloader";
import { ProductContext } from '../../ProductProvider';
//import {useHistory} from 'react-router-dom';
//import { Routes } from '../../routes';

const config = require('../../config.json');

export default () => {
  //const history=useHistory();
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(true);
  const [downloadFile, downloaderComponentUI] = useFileDownloader();

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
    alertClose();
    }else{
      validateLogin();
    //history.push(Routes.DashboardOverview.path);
    }
  },[loggedUser])

  const handleCourseDownload = async(event, item) => {
    event.preventDefault();
    try{
      setLoaded(false);
      const fetchFile=item.courseCode+".pdf";
      const bucketName=`images.vjdepth.in/docs/courseMaterials/active`;
      /*const params={
          fileName:fetchFile,
          bucketName:`idnticauseraccountspdfinvoicesourcebucket/pdfs/invoice/${propsFile.clientId}/${propsFile.domainId}`
      };*/
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      await axios.get(`${config.api.invokeURL}/dashboard/coursematerials/download?fileName=${fetchFile}&bucketName=${bucketName}`,{
        headers: {
          Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
     ).then(async(response)=>{
         // console.log("Response : ", response);
          const file ={
              name:item.courseCode,
              file:response.data.downloadURL,
              filename:fetchFile
          };
          //console.log("url file : ", file);
          setLoaded(true);
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: "Successfully Downloaded"
        }); 
    
          downloadFile(file);
      }).catch((error)=>{
        setLoaded(true);
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Error Occured - " + error.message
      }); 
      })        
    }catch(error){
      setLoaded(true);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
        //console.log("Error : ", error);
    }
}

  return (
    <>
        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>EBooks</Breadcrumb.Item>
          </Breadcrumb>
          <h4>EBooks</h4>
       
        </div>
        <Table hover className="user-table align-items-center">
            <thead>
            <tr>
            <th>#</th>
            <th>Application No</th>
            <th>Name</th>
            <th>Date Applied</th>
            <th>Course Type</th>
            <th>Action</th>
            </tr>
             </thead>
            <tbody>
            {courseMaterialData.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1} </th>
            <td>{item.courseCode}</td>
            <td>{item.courseTitle}</td>
            <td>{item.semester}</td>
            <td>{item.optional}</td>
           <td>
           <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item   onClick={(event)=>{handleCourseDownload(event, item)}}>
                <FontAwesomeIcon icon={faDownload} className="me-2" /> Download
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </td>
            </tr>)}
            </tbody>
            </Table>   
            {downloaderComponentUI}
 </>
  );
};
