import React from 'react'
import { Col, Row, Form } from 'react-bootstrap';

export default function CourseOptions(props) {
    const {enrollCourse, payAmount, handlePayOptions, currentPayStatus, payMsg} = props;
  return (
      <>
    <Row>
            {/*<Col md={6} className="mb-3">
              <Form.Group id="category">
                <Form.Label>Category</Form.Label>
                <Form.Select value={enrollCourse.courseOption} 
                onChange={(event)=>{setEnrollCourse({...enrollCourse, courseOption:event.target.value})}}> 
                  <option value="Online">Online</option>
                  <option value="Correspondence">Correspondence</option>
                </Form.Select>
              </Form.Group>
  </Col>*/}
            <Col md={6} className="mb-3">
              <Form.Group id="payOptions">
                <Form.Label>Payment Options</Form.Label>
                <Form.Select value={enrollCourse.payOptions} 
              //  onSelect={processPayAmount()} 
              //  onChange={(event)=>{setEnrollCourse({...enrollCourse, payOption:event.target.value})}}
              onChange={(event)=>{handlePayOptions(event, event.target.value)}}
              >
                  <option value="Full">Full</option>
                  <option value="Phase1">Phase1</option>
                  <option value="Phase2">Phase2</option>

                </Form.Select>
              </Form.Group>
            </Col>

            </Row>
            <Row>
            <Col md={12} className="mb-3">
              <Form.Group id="payOptions">
                <Form.Label>Amount to be paid now : {payAmount}</Form.Label>
              </Form.Group>
            </Col>
            </Row>
            {currentPayStatus==="Pending" && payMsg!==""?
            <p>{payMsg}</p>:null}
    </>
  )
}
