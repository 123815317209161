import React, { useEffect, useState, useContext } from 'react';
import { Table } from '@themesberg/react-bootstrap';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert  } from "reactstrap";
import { ProductContext } from '../../ProductProvider';
import { Breadcrumb } from '@themesberg/react-bootstrap';
import Preloader from "../../components/Preloader";
import { Auth } from 'aws-amplify';
import axios from 'axios';
import {Helmet} from 'react-helmet-async';

//import {useHistory} from 'react-router-dom';
//import { Routes } from '../../routes';

const config = require('../../config.json');

export default function MyMarks() {
    //const history=useHistory();
    const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(false);
    const [myAssessmentSheet, setMyAssessmentSheet]=useState([]);


    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          alertClose();
          fetchMyMarks();
        }else{
          validateLogin();
        //history.push(Routes.DashboardOverview.path);
        }
      },[loggedUser])
    
    const fetchMyMarks=async()=>{
        setLoaded(false);
        try{
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;  
            await axios.get(`${config.api.invokeURL}/dashboard/scores/${loggedUser.nickname}`, {
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
              // console.log("response : ", response);
                if(response.data.length>0)
                {
               //     console.log("order status")
                    //setProfileDetails(response.data[0]);
                    setMyAssessmentSheet(response.data);
                    setAlertDetails({
                      ...alertDetails,
                      style:"success",
                      open:true,
                      message: response.data.length +" - Score Sheet Found"
                    });  
                  setLoaded(true);
    
                }
                setLoaded(true);

            }).catch((error)=>{
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);
          })
    
        }catch(error){
          setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
    
      }
    }

return (
<>
<Helmet>
      <title>My Marks - Student's Corner - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="My Marks - Student's Corner - VJDepth - Distance Education Programme in Theology. Login to access all the features of VJDepth Student's Corner." />
      <meta name="keywords" content="My Marks - Marks Section - Student's Corner - VJDepth - Distance Education Programme in Theology. Student's portal for all their training needs." /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>My Marks</Breadcrumb.Item>
          </Breadcrumb>
          <h4>My Marks</h4>
       
        </div>

        {myAssessmentSheet.length>0?<Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Course Name</th>
                <th>Course Code</th>
                <th>Status</th>
                <th>Marks</th>
                </tr>
            </thead>
            <tbody>
            {myAssessmentSheet.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.courseName}</td>
            <td>{item.courseCode}</td>
            <td>{item.assessmentResult==="PASS"?<p style={{backgroundColor:"green", borderRadius:"10px", textAlign:"center", color:"white"}}>Pass</p>:
            <p style={{backgroundColor:"red", borderRadius:"10px", textAlign:"center", color:"white"}}>Pending</p>}</td>
            <td>{item.totalMarksObtained}</td>
            </tr>)}
            </tbody>
        </Table>  : <div><p>No Score Card Found</p></div>}
    </>
  )
}
