import React, { useEffect, useState, useContext } from 'react';
import { courseMaterialData } from '../../ProData';
import { Form} from '@themesberg/react-bootstrap';
import { Alert  } from "reactstrap";
import { Row} from '@themesberg/react-bootstrap';
import '../../App.css';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import ProgressBarStatus from '../../components/ProgresssBarStatus';
//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from 'react-bootstrap/Tooltip';
import PhaseJourney from './PhaseJourney';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');

export default function CourseJourney() {
       //const {profileDetails}=props;
       const { loggedUser, alertClose, alertDetails, setAlertDetails, validateLogin } = useContext(ProductContext);
       const [myAssessmentSheet, setMyAssessmentSheet]=useState([]);
       const [phase1Data, setPhase1Data]=useState([]);
       const [phase2Data, setPhase2Data]=useState([]);
       const [loaded, setLoaded] = useState(false);
       const [progressValue1, setProgressValue1]=useState(0);
       const [progressValue2, setProgressValue2]=useState(0);
       const [enablePhase1, setEnablePhase1] = useState(true);

       useEffect(()=>{
              if(loggedUser.isAuthenticated){
                alertClose();
                processingData();
              }else{
                validateLogin();
              //history.push(Routes.DashboardOverview.path);
              }
       },[loggedUser])

       const processingData=async()=>{
       try{
              setLoaded(false);
              const session = await Auth.currentSession();
              const access_token=session.idToken.jwtToken;
              //const clientID="VJDEPTH";
              const registrationno=loggedUser.nickname;
              await axios.get(`${config.api.invokeURL}/dashboard/scores/${registrationno}`,{
              headers: {
                     Authorization: access_token,
                     'x-api-key': config.api.key
              }}, 
              ).then(async(response)=>{
              //console.log("Res : ", response);
              await journeyMapCreation(response.data);
              /*const tTempResponse=response.data;
              var courseCompleted=0;
              for(const i in tTempResponse){
                     if(tTempResponse[i].assessmentResult==="PASS"){
                           // console.log("value : ", tTempResponse[i].registrationNo);
                            courseCompleted=courseCompleted+1;
                     }
              }
              const tProgressValue=((courseCompleted/tTempResponse.length)*100).toFixed(2);
              setProgressValue(tProgressValue);
              //const responseReceived=response.data;*/
              setAlertDetails({
                     ...alertDetails,
                     style:"success",
                     open:true,
                     message: "Student Journey Loaded Successfully"
              });  
              //console.log(response);
              setLoaded(true);
              }).catch((error)=>{
              //console.log(error);
              setAlertDetails({
                     ...alertDetails,
                     style:"warning",
                     open:true,
                     message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
              })  
       }catch(error){
              setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
              }); 
              setLoaded(true);
       }
       }

       const journeyMapCreation=(rawData)=>{
              //console.log("Raw Data : ", rawData);
               var progressObject={};
               var progressArray=[];
               var course1Completed=0;
               var course2Completed=0;
               for(const item in courseMaterialData){
                     progressObject.courseCode=courseMaterialData[item].courseCode;
                     progressObject.courseName=courseMaterialData[item].courseTitle;
                     progressObject.totalMarksObtained=0;
                     progressObject.assessmentResult="FAIL";
                     for(const i in rawData){
                     if(courseMaterialData[item].courseCode===rawData[i].courseCode){
                            if(rawData[i].assessmentResult==="PASS"&& rawData[i].courseCode.startsWith("1")){
                            // console.log("value : ", rawData[i].registrationNo);
                             course1Completed=course1Completed+1;
                            } else if(rawData[i].assessmentResult==="PASS"&& rawData[i].courseCode.startsWith("2")){
                                   course2Completed=course2Completed+1;
                            }
                            progressObject.courseCode=courseMaterialData[item].courseCode;
                            progressObject.courseName=courseMaterialData[item].courseTitle;
                            progressObject.totalMarksObtained=rawData[i].totalMarksObtained;
                            progressObject.assessmentResult=rawData[i].assessmentResult;
                            //totalCourses=totalCourses+1;
                     }
                     }
                     progressArray.push(progressObject);
                     progressObject={};
                 //console.log("registration no : ", sortedcourseArray[item].registrationNo)
/*                 const tcompleted=(courseCompleted/totalCourses)*100;
                 progressObject.registrationNo=sortedcourseArray[item].registrationNo;
                 progressObject.courseCompleted=courseCompleted;
                 progressObject.totalCourses=totalCourses;
                 progressObject.completedPercentage=tcompleted;
                 progressArray.push(progressObject);
                 progressObject={};*/
               }
               //console.log("Array : ", progressArray);
               const tphase1Data = progressArray.filter((item) => item.courseCode.startsWith("1"));
               const tphase2Data = progressArray.filter((item) => item.courseCode.startsWith("2"));
               setMyAssessmentSheet(progressArray);
               setPhase1Data(tphase1Data);
               setPhase2Data(tphase2Data);
               const tProgressValue1=((course1Completed/tphase1Data.length)*100).toFixed(2);
               const tProgressValue2=((course2Completed/tphase2Data.length)*100).toFixed(2);
               setProgressValue1(tProgressValue1);
               setProgressValue2(tProgressValue2);
 
              // setSearchResults(progressArray);
       }

       

    return (
       <>
       <Helmet>
              <title>Course Journey - Student's Corner - VJDepth - Distance Education Programme in Theology</title>
              <meta name="description" content="Course Journey - Student's Corner - VJDepth - Distance Education Programme in Theology. Students can view their Course Journey of each phase with the course code, course name and marks. Course Journey will give complete details about the entire Theology study program." />
              <meta name="keywords" content="Course Journey - Student's Corner - VJDepth - Distance Education Programme in Theology. Student's portal for all their training needs." /> 
       </Helmet>

       <Preloader show={loaded ? false : true} />
       <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
       </Alert>

       <div className='card card-layoutd-flex justify-content-between flex-wrap align-items-center  ' style={{ overflowX:"auto"}}>
       <h1 className='text-center' style={{ paddingTop:"30px"}}>{loggedUser.nickname} - Journey</h1>

       <div className="d-flex table-settings mb-4">        
       <Row className="justify-content-between align-items-center text-center">
          <div className="d-flex " style={{margin:"0"}}>
              Phase 1 
          <Form.Check 
            type="switch"
            id="custom-switch"
            label="Phase 2"
            onClick={(event)=>{setEnablePhase1(!enablePhase1)}}
          />
          </div>
        </Row>
      </div>

      {enablePhase1?<ProgressBarStatus  progressValue={progressValue1} dispValue="Phase 1 - "/>:
       <ProgressBarStatus  progressValue={progressValue2} dispValue="Phase 2 - "/>}


      {enablePhase1?<> <h3 style={{backgroundColor:"grey", borderRadius:"20px", width:"250px", textAlign:"center"}}>Phase 1 Journey</h3>
      <PhaseJourney myAssessmentSheet={phase1Data}/></>:
      <><h3 style={{backgroundColor:"grey", borderRadius:"20px", width:"250px", textAlign:"center"}}>Phase 2 Journey</h3>
      <PhaseJourney myAssessmentSheet={phase2Data}/></>}
      </div>
      </>
    )
  
}
