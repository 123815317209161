import React from 'react';
import spinner from '../../assets/img/spinner.gif';
import wrong from '../../assets/img/wrong.gif';
import confirm from '../../assets/img/confirmation.gif';
import {Card, Row, Col, Table} from 'react-bootstrap';

//const config = require('../../config.json');

export default function orderDetails(props) {
  const {fetchedOrder, loaded, cartDetails, feeDetails}=props;
   
  return (
    <>
    <div className='d-flex justify-content-center text-center '> 
    {!loaded?
   <Card className='p-5'> 
   <Row>
      <Col>
         <h2> Fetching Order Details...</h2>
         <img src={spinner} alt="pending" />
      </Col>
      
    </Row>
            
    </Card>:
    <div>
    {fetchedOrder.orderStatus==="TXN_FAILURE"? 
    <Card className='p-5'>
    <Row>
      <Col>
         <h2 className='text-danger'> Order Status: Failed</h2>
        <img className='m-4' width={150} src={wrong} alt="failure" />
       <h5>Transaction ID: {fetchedOrder.bankTransId}</h5> 
       <p>Date: {fetchedOrder.orderDate}</p>
      </Col>
    </Row>
     </Card>:fetchedOrder.orderStatus==="TXN_SUCCESS"?
     <Card className='p-5'>
    <Row>
      <Col>
         <h2 className='text-success'> Order Status: Success</h2>
        <img className='m-4'  src={confirm} alt="failure" />
      </Col>
    </Row>
    <Row>
      <Col> <h5>Order Id: {fetchedOrder.orderID}</h5> 
      </Col>
      <Col> <h5>Date: {fetchedOrder.orderDate}</h5> 
      </Col>
    </Row>
    <Row>
      <Col> <h5>Amount: {fetchedOrder.orderAmount}</h5> 
      </Col>
      <Col> <h5>Payment: {fetchedOrder.paymentMode}</h5> 
      </Col>
    </Row>
    <h5>Course Details</h5>
    <Row>
      <Col > <h5>Course ID </h5> {cartDetails.courseID}
      </Col>
      
      <Col > <h5>Course </h5> {cartDetails.courseName}
      </Col>
    </Row>
    <Row>
      <Col > <h5>Duration  </h5> {cartDetails.duration}
      </Col>
      <Col> <h5>Total Fees </h5> {cartDetails.fees}
      </Col>
    </Row>
    <Row>
      <Col > <h5>Class Category </h5> {cartDetails.courseOption}
      </Col>
      <Col > <h5>Payment Option </h5> {cartDetails.payOption}
      </Col>
    </Row>

    <h5>Fee Details</h5>
    <Table responsive hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Description</th>
              <th className="border-bottom">Fees</th>              
            </tr>
          </thead>
          <tbody>
          {feeDetails.map((item, index)=>
          <tr key={index} >
            <td>
                {index+1}
            </td>
            <td>
               {item.feeCategory}
            </td>
            <td>
                {item.amount}
            </td>
            </tr>)}
          </tbody>
        </Table>
     </Card>:<Card className='p-5'> 
   <Row>
      <Col>
         <h2> Order Status: Initiated but Not Completed</h2>
         <img src={spinner} alt="pending" />
      </Col>
      
    </Row>
            
    </Card>}
     </div>}
            
            </div>
    </>
  )
}
