import React from 'react';
import { Col, Row, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

import { Input, Badge } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';


export default function ViewRequestForm(props) {
    const {selectedRequest, handleAttachmentDownload}=props;

  return (
  <div>
  <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">View Message</h5>      
        {selectedRequest.msgID}       <Badge className='m-3 ' color={selectedRequest.msgStatus==="New"?"primary":selectedRequest.msgStatus==="Closed"?"success":selectedRequest.msgStatus==="Cancelled"?"warning":"info"}>
      {selectedRequest.msgStatus}</Badge>

        <Row>
          <Col>
          Date : {selectedRequest.msgCreationDate}
          </Col>
          <Col>
          Time : {selectedRequest.msgCreationTime}
          </Col>
        </Row>

          <Row className='container p-4'>
            <Col>
                <form > 
                <div className="navbar-bg">
                <FormGroup style={{overflow:"visible"}}>
                <div>
                <Row>
                  <Input
                        type="text"
                        name="subject"
                        value={selectedRequest.msgSubject}
                        disabled
                      />
                  </Row>
                  </div>
                    </FormGroup>
                    <FormGroup style={{overflow:"visible"}}>
                    <div>
                    <Row>
                  <Input type="textarea" name="msg"
                      value={selectedRequest.msgDescription}
                      disabled
                      />
                  </Row>
                  </div>
                  {selectedRequest.fileAttached && selectedRequest.attachmentName?<span> <FontAwesomeIcon icon={faPaperclip} className="me-2" onClick={(event)=>{handleAttachmentDownload(event, selectedRequest)}}/> {selectedRequest.attachmentName}  </span>:null}
                    </FormGroup>
                  </div>
                  </form>
            </Col>           
          </Row>
      </Card.Body>
    </Card>
    </div>
  )
}
