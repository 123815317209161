import React, {useContext} from 'react';
import { ProductContext } from "../../ProductProvider";

const checksum_lib = require("./checksum");
const config = require("../../config.json");
const https = require("https");
const qs = require("querystring");

export default function PayTM(props) {
    const {orderID,orderAmount,orderedBy}=props.orderDetails;
    const { loggedUser } = useContext(ProductContext);
    
    const paytmInitialize=()=>{
      //  console.log("Props : ", props);
        /*var paymentDetails = {
            amount: "100.00",
            customerId: "ORDER_12345",
            customerEmail: "rjosephxavier@gmail.com",
            customerPhone: "9789099422"
        }*/
       // console.log("paytment details : ", paymentDetails);
       /* if(!paymentDetails.amount || !paymentDetails.customerId || !paymentDetails.customerEmail || !paymentDetails.customerPhone) {
            console.log('Payment failed');
        } else*/
        try {
          //  const orderID='TEST_'  + new Date().getTime();
            const torderAmount=orderAmount.toString();
            var params = {};
            params['MID'] = config.PaytmConfig.mid;
            params['WEBSITE'] = config.PaytmConfig.website;
            params['CHANNEL_ID'] = 'WEB';
            params['INDUSTRY_TYPE_ID'] = 'Retail';
            params['ORDER_ID'] = orderID;
            params['CUST_ID'] = orderedBy;
            params['TXN_AMOUNT'] = torderAmount;
            params['CALLBACK_URL'] = config.PaytmConfig.callbackURL;
            params['EMAIL'] = loggedUser.email;
          //  params['MOBILE_NO'] = paymentDetails.customerPhone;
         //   console.log("params : ", params);

     /*       var paytmParams = {};
                paytmParams.body = {
                    "requestType"   : "Payment",
                    "mid"           : config.PaytmConfig.mid,
                    "websiteName"   : config.PaytmConfig.website,
                    "orderId"       : orderID,
                    "callbackUrl"   : "https://91wedvmnbl.execute-api.ap-south-1.amazonaws.com/v1/student/order/payment/paytm",
                    "txnAmount"     : {
                        "value"     : paymentDetails.amount,
                        "currency"  : "INR",
                    },
                    "userInfo"      : {
                        "custId"    : paymentDetails.customerId,
                    },
                };
                console.log("params : ", paytmParams);*/

                /*
                * Generate checksum by parameters we have in body
                * Find your Merchant Key in your Paytm Dashboard at https://dashboard.paytm.com/next/apikeys 
                */
                  
            checksum_lib.generateSignature(params, config.PaytmConfig.key).then(function(checksum){
             /*   paytmParams.head = {
                    "signature"    : checksum
                };*/

            //checksum_lib.generateSignature(params, config.PaytmConfig.key, function (err, checksum) {
              //  var txn_url = `https://securegw-stage.paytm.in/theia/processTransaction?mid=${config.PaytmConfig.mid}&orderId=${orderID}`; // for staging
                var txn_url = `https://securegw-stage.paytm.in/theia/processTransaction`; // for staging

                // var txn_url = "https://securegw.paytm.in/theia/processTransaction"; // for production
              //  console.log("Checksum : ", checksum)

             /*   var post_data = JSON.stringify(paytmParams);
                var options = {

                    /* for Staging */
                 //   hostname: 'securegw-stage.paytm.in',
            
                    /* for Production */
                    // hostname: 'securegw.paytm.in',
            
              /*      port: 443,
                    path: `/theia/api/v1/initiateTransaction?mid=${config.PaytmConfig.mid}&orderId=${orderID}`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Content-Length': post_data.length
                    }
                };
                console.log("Optons : ", options);
            
                var response = "";
                var post_req = https.request(options, function(post_res) {
                    post_res.on('data', function (chunk) {
                        response += chunk;
                    });
            
                    post_res.on('end', function(){
                        console.log('Response: ', response);
                    });
                });*/
            
               // post_req.write(post_data);
               // post_req.end();
            
               
               var form = document.createElement('form');

             //   var form_fields = "";
               // const tparams=paytmParams.body;
                for (var x in params) {
                    var FN = document.createElement("input");
                    FN.setAttribute("type", "hidden");
                    FN.setAttribute("name", x);
                    FN.setAttribute("value", params[x]);
                    form.appendChild(FN);

                   // form_fields += "<input type='hidden' name='" + x + "' value='" + params[x] + "' >";
                    //console.log("fields :",tparams[x]);
                }
                var FN1 = document.createElement("input");
                    FN1.setAttribute("type", "hidden");
                    FN1.setAttribute("name", "CHECKSUMHASH");
                    FN1.setAttribute("value", checksum);
                    form.appendChild(FN1);
               // form_fields += "<input type='hidden' name='CHECKSUMHASH' value='" + checksum + "' >";
              //console.log("Form : ", form);
                document.body.appendChild(form);
                form.method = 'post';
                form.action = txn_url;
                form.id='myForm';
                form.submit();
              //  post_req.writeHead(200, { 'Content-Type': 'text/html' });
               /* post_req.write('<html><head><title>Merchant Checkout Page</title></head><body><center><h1>Please do not refresh this page...</h1></center><form method="post" action="' + txn_url + '" name="f1">' + form_fields + '</form><script type="text/javascript">document.f1.submit();</script></body></html>');
                post_req.end();*/
            });
        }catch(error){
            console.log("Error : ", error);
        }
    }

    const processToken=(req, res)=> {
        // Route for verifiying payment
      
        var body = '';
      
        req.on('data', function (data) {
           body += data;
        });
      
         req.on('end', function () {
           //var html = "";
           var post_data = qs.parse(body);
      
           // received params in callback
      //     console.log('Callback Response: ', post_data, "\n");
      
      
           // verify the checksum
           var checksumhash = post_data.CHECKSUMHASH;
           // delete post_data.CHECKSUMHASH;
           var result = checksum_lib.verifychecksum(post_data, config.PaytmConfig.key, checksumhash);
       //    console.log("Checksum Result => ", result, "\n");
      
      
           // Send Server-to-Server request to verify Order Status
           var params = {"MID": config.PaytmConfig.mid, "ORDERID": post_data.ORDERID};
      
           checksum_lib.genchecksum(params, config.PaytmConfig.key, function (err, checksum) {
      
             params.CHECKSUMHASH = checksum;
             post_data = 'JsonData='+JSON.stringify(params);
      
             var options = {
               hostname: 'securegw-stage.paytm.in', // for staging
               // hostname: 'securegw.paytm.in', // for production
               port: 443,
               path: '/merchant-status/getTxnStatus',
               method: 'POST',
               headers: {
                 'Content-Type': 'application/x-www-form-urlencoded',
                 'Content-Length': post_data.length
               }
             };
      
      
             // Set up the request
             var response = "";
             var post_req = https.request(options, function(post_res) {
               post_res.on('data', function (chunk) {
                 response += chunk;
               });
      
               post_res.on('end', function(){
             //    console.log('S2S Response: ', response, "\n");
      
                 var _result = JSON.parse(response);
                   if(_result.STATUS === 'TXN_SUCCESS') {
                       res.send('payment sucess')
                   }else {
                       res.send('payment failed')
                   }
                 });
             });
      
             // post the data
             post_req.write(post_data);
             post_req.end();
            });
           });
      }
  return (
      <>
    <button className='text-white br-2 p-2 border-0' style={{fontSize:'15px', backgroundColor:'#1f3a9b', borderRadius:'4px', width:'150px'}} onClick={paytmInitialize}>Pay with PayTM</button>
    </>
    )
}
