import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faSignOutAlt, faBell } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Image, Navbar, Dropdown, Container, ListGroup } from '@themesberg/react-bootstrap';
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";

//import NOTIFICATIONS_DATA from "../data/notifications";
//import Profile3 from "../assets/img/team/profile-picture-3.jpg";
import { ProductContext } from "../ProductProvider";
import {Routes} from '../routes';


export default (props) => {
  //const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const { loggedUser, setLoggedUser, myProfileImage, handleImageError, validateLogin, openNotifications, setOpenNotifications } = useContext(ProductContext);
  const history=useHistory();
  const [pageLoad, setPageLoad]= useState(false);
  const areNotificationsRead = openNotifications.reduce((acc, notif) => acc && notif.read, true);

  useEffect(()=>{
    if(loggedUser && myProfileImage){
      setPageLoad(true);
    }else{
      setPageLoad(false);
      validateLogin();
    }
  },[myProfileImage, loggedUser, openNotifications])

  const handleSignOut=async()=>{
    //console.log("inside sighout");
    try{
      await Auth.signOut()
      .then((res)=>{
        setLoggedUser({...loggedUser,
          name:'',
          email:'',
         // currentStatus:'',
          DOB:'',
          profile:'',
          provinceName:'',
          website:'',
          isAuthenticated:false
        }); 
        history.push(Routes.Signin.path);

      }).catch((error)=>{
        console.log("Error ", error.message);
      })
    }catch(error){
      console.log("Error ", error.message);
    }
  }

  const handleNotificationNav =()=>{
    history.push(Routes.InboxOpenNotification.path);
  }

  const handleProfileNav =()=>{
    history.push(Routes.MyProfile.path);
  }

  /*const handleNotificationNav =()=>{
    history.push(Routes.OpenList.path);
  }*/

  const handleChangePwdNav =async()=>{
    history.push(Routes.ResetPassword.path);
  }


  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setOpenNotifications(openNotifications.map(n => ({ ...n, read: true })));
    }, 300);
  };


  const Notification = (props) => {
    const { link, msgSubject, msgCreationDate, msgDescription, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
         {/*} <Col className="col-auto">
            <Image src={image} className="user-avatar lg-avatar rounded-circle" />
    </Col>*/}
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{msgSubject}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{msgCreationDate}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{msgDescription}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
     {pageLoad? <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <h4>Distance Education in Theology (DEPTH)</h4>
           {/* <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form> */}
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>
                  <div onClick={handleNotificationNav}>
                  {openNotifications.map(n => <Notification key={`notification-${n.msgID}`} {...n} />)}
                  </div>
                  <Dropdown.Item nClick={handleNotificationNav} className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
          </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={myProfileImage} onError={handleImageError} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{loggedUser.name}</span>
                  </div>
                </div>
                </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item onClick={handleProfileNav} className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                </Dropdown.Item>
            
                <Dropdown.Item onClick={handleChangePwdNav} className="fw-bold">
                  <FontAwesomeIcon icon={faLock} className="me-2" /> Change Password
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold" onClick={handleSignOut}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>:null}
    </Navbar>
  );
};
