import React from 'react';
import vjdepth from "../../assets/vjdepth-logo.png";

export default function LandingPage() {
  return (
    <div style={{textAlign: "center"}}>
        <br />
        <br />
        <br />
        <br />
        <br />
          <h3>Welcome To VJDEPTH</h3>
        <img src={vjdepth} alt="VJDepth" style={{width:"12rem", height:"12rem", borderRadius:"50%"}} ></img>
      
    <h1>Student's Portal</h1></div>
  )
}
