import React from 'react';

export default function GeneralInstructions() {

  return (
    <>
    <div>General Instructions</div>


    </>
  )
}
