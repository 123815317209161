import React, {useEffect, useState, useContext} from 'react';
import { faEllipsisH, faCheck, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert } from "reactstrap";
import { Breadcrumb, Button, Dropdown, Table, ButtonGroup } from '@themesberg/react-bootstrap';
import MCQModal from '../exam/MCQModal';
import YesNoModal from '../exam/YesNoModal';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import DescriptiveModal from '../exam/DescriptiveModal';
import GeneralInstructions from '../exam/GeneralInstructions';
import {Helmet} from 'react-helmet-async';

const config = require('../../config.json');


export default function Exams() {
  const { alertClose, alertDetails, setAlertDetails, loggedUser, validateLogin, generateDate, handleProcessAssessment } = useContext(ProductContext);
  const [loaded, setLoaded] = useState(false);
  const [initials, setInitials] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [questionID, setQuestionID]=useState();
  const [formType, setFormType] = useState("View");
  const [questionTable, setQuestionTable] = useState([]);
  const [displayQuestions, setDisplayQuestions]=useState([]);
  const [generalCollapsed, setGeneralCollapsed]= useState(true);

  useEffect(()=>{
    if(loggedUser.isAuthenticated){
      alertClose();
      fetchData();
      setInitials(true);
    }else{
      validateLogin();
      //history.push(Routes.DashboardOverview.path);
    }
  },[loggedUser])

  const fetchData=async()=>{
    try{
      setLoaded(false);
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      const batch=(loggedUser.nickname).substring(0,2);
      const fetchOption="Individual";
      await axios.get(`${config.api.invokeURL}/dashboard/exam/assigned-exam?fetchid=${batch}&fetchOption=${fetchOption}&registrationno=${loggedUser.nickname}`,{
          headers: {
            Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
      //  console.log("Response : ", response);
          const examResponse=response.data.exam;
          const assessmentResponse=response.data.assess;
          if(assessmentResponse.length>0){
            for(const i in examResponse){
              examResponse[i].displayStatus="Pending";
                for(const j in assessmentResponse){
                  if(examResponse[i].questionID===assessmentResponse[j].courseCode && (assessmentResponse[j].assessmentStatus==="Completed" || assessmentResponse[j].assessmentStatus==="Pending Evaluation")){
                //    console.log("completed Q: ", examResponse[i].questionID);
                //    console.log("completed A: ", assessmentResponse[j].courseCode, assessmentResponse[j].assessmentStatus );
                    examResponse[i].displayStatus="Completed";
                  }else if(examResponse[i].questionID===assessmentResponse[j].courseCode && assessmentResponse[j].assessmentStatus==="Initiated"){
                //    console.log("ini Q: ", examResponse[i].questionID);
                //    console.log("ini A : ", assessmentResponse[j].courseCode,assessmentResponse[j].assessmentStatus );
                    examResponse[i].displayStatus="Initiated";
                  }/*else{
                    console.log("Pend Q : ", examResponse[i].questionID);
                    console.log("Pend A : ", assessmentResponse[j].courseCode,assessmentResponse[j].assessmentStatus );
                    examResponse[i].displayStatus="Pending";
                  }*/
                }
               // console.log("----------");
              }    
              //const res1 = assessmentResponse.filter((page1) => !examResponse.find(page2 => page1.url === page2.url )) 
              //console.log(res1)
         
          }
        //  console.log("Exam : ", examResponse);
          setQuestionTable(examResponse);
          setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: examResponse.length +" - Questions Found"
          });  
          setLoaded(true);
      }).catch((error)=>{
          setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message:"Error Occured - " + error.message
          }); 
          setLoaded(true);
      })  
  }catch(error){
      setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message:"Error Occured - " + error.message
      }); 
      setLoaded(true);
  }
  }

  const handleQuestionDisplay =(event, item, mode)=>{
    event.preventDefault();
    //console.log("Item :", item);
    setQuestionID(item);
    setDisplayQuestions(item.assessmentQuestions);
    setFormType(mode);
    generalToggle();
  }

  const toggle = () => {
         setCollapsed(!collapsed);
  }

  const generalToggle = () => {
        setGeneralCollapsed(!generalCollapsed);
    }

    const handleGeneralSubmit=async()=>{
      setLoaded(false);
      const todayDate=generateDate();  
      const tassessmentID=questionID.questionID+"#"+loggedUser.nickname;
      const params={
          registrationNo:loggedUser.nickname,
          assessmentID:tassessmentID,
          courseCode:questionID.questionID,
          courseName:questionID.courseName,
          assessmentStatus:'Initiated',
          totalMarksObtained:"0",
          totalMarks:"0",
          assessmentGrade:"NA",
          assessmentResult:"NA",
          assessmentCategory:"Online",
          assessmentResponse:[],
          createdBy:loggedUser.nickname,
          createdOn:todayDate,
          updatedBy:loggedUser.nickname,
          updatedOn:todayDate,
          assessmentRemarks:"",
          examDate:todayDate,
          examType:questionID.assessmentType,
          evaluatedBy:"",
          evaluatedOn:""
      }
      //console.log("Params : ", params);
      await handleProcessAssessment(params);
      generalToggle();
      toggle();
      setLoaded(true);
    }


  return (
    <>
    <Helmet>
      <title>Online Exam - Student's Corner - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Online Exam Section - Student's Corner - VJDepth - Distance Education Programme in Theology. Students can take their exams online." />
      <meta name="keywords" content="Online Exams - Student's Corner - VJDepth - Distance Education Programme in Theology. Student's portal for all their training needs." /> 
    </Helmet>

        <Preloader show={loaded ? false : true} />
        <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>

        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Student</Breadcrumb.Item>
            <Breadcrumb.Item active>Exams</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Exams</h4>
       
        </div>

      {/*View && Edit*/}
      <Modal isOpen={!collapsed} style={{top:'10%'}}>
        <ModalHeader>
        {questionID?<p>{questionID.courseName} - {questionID.assessmentCategory} Exam</p>:null}
        </ModalHeader>
        <ModalBody> 
          {questionID && questionID.assessmentType==="YesNo"? 
          <YesNoModal questionID={questionID} formType={formType} displayQuestions={displayQuestions} toggle={toggle}/>:
          (questionID && questionID.assessmentType==="MCQ")
          ?<MCQModal questionID={questionID} formType={formType} displayQuestions={displayQuestions} toggle={toggle}/>:
          <DescriptiveModal questionID={questionID} formType={formType} displayQuestions={displayQuestions} toggle={toggle}/>}
        </ModalBody>
        {/* <ModalFooter>
        {' '}
        <Button onClick={toggle}>
            Close
        </Button>
        </ModalFooter>*/}
      </Modal>

      {/*General Guidelines*/}
      <Modal toggle={generalToggle} isOpen={!generalCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={generalToggle}>
                      Exam - General Guidelines
                    </ModalHeader>
                    <ModalBody>  
                      <GeneralInstructions />
                    </ModalBody>
                    <ModalFooter>
                <Button onClick={handleGeneralSubmit}>
                    Start
                </Button>

                {' '}
                <Button onClick={generalToggle}>
                    Close
                </Button>
                </ModalFooter>
      </Modal>
      
    <div >
        <Table responsive hover className="user-table align-items-center">
             <thead>
                <tr>
                <th>#</th>
                <th>Question ID</th>
                <th>Assessment Type</th>
                <th>Status</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {initials && questionTable.map((item, index)=>
            <tr key={index}>
            <th scope="row">{index+1}</th>
            <td>{item.questionID}</td>
            <td>{item.assessmentType}</td>
            <td>{item.displayStatus}</td>
           <td>
           {item.displayStatus==="Pending"?<Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="text-success" onClick={(event)=>{handleQuestionDisplay(event, item, "Exam")}}>
                <FontAwesomeIcon icon={faCheck} className="me-2" /> Initiate Exam
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>:null}
            </td>
            </tr>)}
            </tbody>
            </Table>
    </div>
    </>
  )
}
