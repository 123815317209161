import React,{useState, useContext, useEffect} from 'react';
import Calendar from 'react-awesome-calendar';
import {Modal, ModalBody, ModalHeader, ModalFooter, Alert  } from "reactstrap";
import CalendarForm from './CalendarForm';
import { Button, Table, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { faEye, faEllipsisH, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductContext } from '../../ProductProvider';
import Preloader from "../../components/Preloader";
import { eventsColour } from '../../ProData';
import axios from "axios";
import { Auth } from "aws-amplify";
import EventView from './EventView';

const config = require('../../config.json');

/*var events = [{
    id: 1,
    color: '#fd3153',
    from: '2022-10-18T18:00:00+00:00',
    to: '2022-10-18T19:00:00+00:00',
    title: 'Mass - Thanks Giving'
}, {
    id: 4,
    color: '#fd3153',
    from: '2022-10-18T18:00:00+00:00',
    to: '2022-10-18T19:00:00+00:00',
    title: 'Mass - RIP'
},{
    id: 2,
    color: '#1ccb9e',
    from: '2022-10-18T13:00:00+00:00',
    to: '2022-10-18T14:00:00+00:00',
    title: 'This is another event'
}, {
    id: 3,
    color: '#3694DF',
    from: '2022-10-18T13:00:00+00:00',
    to: '2022-10-18T20:00:00+00:00',
    title: 'This is also another event'
}];*/


export default function CalendarView() {
    //const [calendarDetails, setCalendarDetails]=useState({id:'', startTime:'', endTime:'', eventType:'',comments:'', presenter:'', location:'', audience:'' });
   // const [collapsed, setCollapsed] = useState(true);
    const [viewCollapsed, setViewCollapsed] = useState(true);
    const [loaded, setLoaded]=useState(true);
    const { alertDetails, setAlertDetails, alertClose, loggedUser, validateLogin} = useContext(ProductContext);
    const [eventsData, setEventsData]=useState([]);
    const [responseData, setResponseData]=useState([]);
    const [eventItem, setEventItem]=useState();

    useEffect(()=>{
        if(loggedUser.isAuthenticated){
          alertClose();
          const tDate= generateCalendarDate();
          fetchEvents(tDate);
          //setEventsData(events);
        }else{
        validateLogin();
        alertClose();
        }
        return()=>{
          alertClose();
        }
       // console.log(batchYear);
      },[loggedUser])

      const fetchEvents=async(tDate)=>{
          try{
            setLoaded(false);
            //const searchTopic="BatchFeeStatus";
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken; 
            const batch=loggedUser.nickname.substring(0,2);
            //console.log("batch : ", batch);
          //await axios.get(`${config.api.invokeURL}/dashboard/reports/fee-batchwise?fdetchKey=${searchValue}&fetchOption=${searchTopic}`, {
            await axios.get(`${config.api.invokeURL}/dashboard/calendar/batch?calendarMonth=${tDate}&batch=${batch}`, {  
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          }).then(async(response) => {
            //console.log("Response : ", response);
            if(response.data.length>0)
            {
                setResponseData(response.data);
                processEvents(response.data);
                setAlertDetails({
                  ...alertDetails,
                  style:"success",
                  open:true,
                  message: "Calendar Events fetched Successfully!"
              });  
    
            }
            setLoaded(true);
    
        })
          }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);        
          }
      }

      const processEvents=(tEvents)=>{
        var tempEvents=[];
        for(const item in tEvents){
            var tItem={};
            tItem.id=tEvents[item].calendarID;
            tItem.color=tEvents[item].color;
            tItem.from=tEvents[item].startFrom;
            tItem.to=tEvents[item].endTo;
            tItem.title=tEvents[item].title;
            tempEvents.push(tItem);
        }
        setEventsData(tempEvents);
      }

      const generateCalendarDate=()=>{
        const current_datetime = new Date();
        var tempUserId="";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        const accountNo = tempUserId.concat(date_year,"-",month_value);
        //setUserId(accountNo);
        //setMemberInfo({...memberInfo, userId:accountNo});
        return accountNo;
        //console.log("acc : ", accountNo);
      
    }

    /*const handleTimeLineClick=(e)=>{
        //console.log("timeline click : ", e);
        const testStart=new Date(e.year, e.month,e.day,e.hour);
        const testEnd=new Date(e.year, e.month,e.day,e.hour+1);
        setCalendarDetails({...calendarDetails, 
            startTime:testStart,
            endTime:testEnd});
        //console.log("formatted fime : ", test);
        toggle();
    }*/

   /* const handleNewClick=(e)=>{
        e.preventDefault();
        //console.log("timeline click : ", e);
        const utcStartTime=new Date();
        const utcEndTime=new Date();
        setCalendarDetails({...calendarDetails, 
            startTime:utcStartTime,
            endTime:utcEndTime});
        toggle();
    }*/

    /*const handleEventSave=async()=>{
        setLoaded(false);
        const utcStartTime=new Date(calendarDetails.startTime).toISOString();
        const utcEndTime=new Date(calendarDetails.endTime).toISOString();
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken; 
        const tid=await generateOrderNo();
        const tDate= await generateDate();
        const tColour=eventsColour.filter(item=>item.id===calendarDetails.eventType);
        //console.log("Colour : ", tColour[0].color);

        try{
            const params={
                calendarID:tid,
                color: tColour[0].color,
                startFrom:utcStartTime,
                endTo:utcEndTime,
                title:calendarDetails.eventType,
                comments:calendarDetails.comments,
                presenter:calendarDetails.presenter,
                location:calendarDetails.location,
                audience:calendarDetails.audience,
                updatedBy:loggedUser.name,
                updatedOn:tDate
            }
            //events.push(params);
            //console.log("Params : ", params);
            //console.log("Events : ", events);
            //setEventsData(events);
            await axios.post(`${config.api.invokeURL}/dashboard/calendar`,params, {  
                headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                }
              }).then(async(response) => {
               //console.log("Response : ", response);
                //await fetchCurrentYearDetails();
                  setAlertDetails({
                    ...alertDetails,
                    style:"success",
                    open:true,
                    message: "Calendar Entry Created Successfully!"
                });  
                toggle();
                setLoaded(true)
            }).catch((error)=>{
              setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            });        
            setLoaded(true);
            window.scrollTo(0,0);
          })
        }catch(error){
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message: "Error Occured : " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);  
        }
    }*/

    /*const toggle = () => {
        setCollapsed(!collapsed);
    }*/

    const viewToggle = () => {
        setViewCollapsed(!viewCollapsed);
    }

    const handleChange=(event)=>{
        //console.log("Change Event : ", event);
        if(event.mode==="monthlyMode"){
            const tYear=event.year.toString();
            const tMonth=(event.month+1).toString();
            var month_value="";
            tMonth.length===1? (month_value=month_value.concat(0,tMonth)):(month_value=tMonth);
    
            const tDate=tYear+"-"+month_value;
            fetchEvents(tDate);
            //console.log("Date : ", tDate);
        }
    }

    const handleClick=(event)=>{
        const temp=responseData.filter(item=>item.calendarID=event);
        const startTest=new Date(temp[0].startFrom);
        const endTest=new Date(temp[0].endTo);
        var tEvent=temp[0];
        tEvent.startFrom=startTest;
        tEvent.endTo=endTest;
        //console.log("selected Item : ", tEvent);
        setEventItem(tEvent);
        viewToggle();
    }

  return (
      <>
          <Preloader show={loaded ? false : true} />
    <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
    {alertDetails.message}
    </Alert>

       {/*Calendar Form*/}
      {/*} <Modal toggle={toggle} isOpen={!collapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={toggle}>
                        Calendar - Add Events 
                    </ModalHeader>
                    <ModalBody>   
                        <CalendarForm calendarDetails={calendarDetails} setCalendarDetails={setCalendarDetails}/> 
                    </ModalBody>
                    <ModalFooter>
                    <Button onClick={handleEventSave}>
                Save
            </Button>

            {' '}
            <Button onClick={toggle}>
                Close
            </Button>
            </ModalFooter>
        </Modal>*/}

         {/*Event View*/}
       <Modal toggle={viewToggle} isOpen={!viewCollapsed} style={{top:'10%'}}>
                    <ModalHeader toggle={viewToggle}>
                        Calendar - View Event
                    </ModalHeader>
                    <ModalBody>   
                        <EventView eventItem={eventItem}/> 
                    </ModalBody>
                    <ModalFooter>
            </ModalFooter>
        </Modal>

    <div>Course Calendar</div>

    {/*<div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
    <Dropdown as={ButtonGroup}>
    <Dropdown.Toggle as={Button} variant="secondary" className="text-dark me-2" onClick={(event)=>{handleNewClick(event)}}>
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            <span>New</span>
          </Dropdown.Toggle>
          </Dropdown>
          {/*<div className="d-flex">
          <Form.Check 
            type="switch"
            id="custom-switch"
            label="Active"
          />
  </div>
          </div>*/}

        <Calendar
                events={eventsData}
                //onClickTimeLine={event=>handleTimeLineClick(event)}
                onChange={event=>handleChange(event)}
                onClickEvent={event=>handleClick(event)}
            />
      
      
      </>
  )
}
