import React, { useEffect } from 'react';
//import axios from "axios";
//import { Auth } from "aws-amplify";
//import useFullPageLoader from "../hooks/useFullPageLoader";
//import { ProductContext } from '../../ProductProvider';
//const config = require('../../config.json');


export default function ProfileImage(props) {
    //const { setDispMsg } = useContext(ProductContext)
  //  const [image, setImage] = useState('');
    const { profileImage}=props;
    //const [loader, showLoader, hideLoader] = useFullPageLoader(); 

useEffect(() => {
  //setImage(`${config.s3.imageURL}${profileImage}`);
 //console.log("profile image = ", `${config.s3.imageURL}${profileImage}`);
//  console.log("props:", props);
}, [profileImage])

/*const AddImagetoS3 =async(imageFile,tfileName) => {
  //console.log("Files selected : ",files);
  setDispMsg('');
  const timestamp=new Date().getTime();
  const fileName=tfileName+"-"+timestamp+".jpeg";
  console.log("filename : ", fileName);
  //const f=files[0];
  try{
      //getting presigned URL
  const session = await Auth.currentSession();
  const access_token=session.idToken.jwtToken;
  const bucketName=config.s3.bucketName;
  //const fileName=tfileName;
  //console.log("selected file : ", selectedImageFile['name']);
  //console.log("Image file : ", imageFile.name);
    //var f=imageFile;
    //console.log("initial file : ", f);
    
    var file = imageFile;
    var blob = file.slice(0, file.size, 'image/jpeg'); 
    const newFile = new File([blob], fileName, {type: 'image/jpeg'});
   // console.log("after update : ", newFile);
  await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${fileName}&bucketName=${bucketName}`,{
  //await axios.get(`${config.api.invokeUrl}/presigned/uploader?fileName=${fileName}&bucketName=${bucketName}`,{
      headers:{
          Authorization: access_token,
          'x-api-key': config.api.key
      }
  })
   .then(async(response)=>{
     //  console.log("response : ", response);
     //  console.log("selected file : ", newFile);
      await fetch(response.data.uploadURL, {
      method: 'PUT',
      headers:{
          "Content-Type":"image/jpeg"
      },
      body: newFile
      
    });
   // setUser({...user,profileImage:fileName});
    setDispMsg("Image Successfully Uploaded!");*/
    //console.log("filename : ", fileName);
   // f.remove();
  //  fetchGalleryImages();

    //console.log("Result : ",result);
    
      /*await axios.put(response.data.uploadURL,{body:f['file']},{
          headers:{
              "Content-Type":"image/jpeg"
          }
        }).then(()=>{
            //console.log("Result : ", res);
            setDispMsg("Image Successfully Uploaded!");
        }).catch((error)=>{
          setDispMsg("Error Occured - ", error.message);
        })*/
   /*}).catch((error)=>{
      setDispMsg("Error Occured - ", error.message);
   })
    
  }catch(error){
      setDispMsg("Error Occured - ", error.message);

  }
  return fileName;

   }*/

   /*const handleFileSelection = (event)=>{
     event.preventDefault();
     if (event.target.files && event.target.files.length > 0) {
       setImageFile(event.target.files[0]);
       //console.log("selected file : ", event.target.files[0].name);
       var tprofile = document.getElementById("profile");
       tprofile.src=URL.createObjectURL(event.target.files[0]);
       setAlertDetails({
        ...alertDetails,
        style:"info",
        open:true,
        message:"File Selected - "+ event.target.files[0].name
    });   
   }else{
    setAlertDetails({
      ...alertDetails,
      style:"info",
      open:true,
      message:"No Files selected"
  });   
   }
  }*/

 /* const postOnS3 = async()=>{
    try{
      const newFileName=fileName+".jpeg";
      const bucketName=config.s3.bucketName;
      console.log("bucket name : ", bucketName);
      var file = imageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
      console.log("New file : ", newFile);
      await axios.get(`${config.api.invokeUrl}/registration/profile_avatar/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
           headers:{
               //Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
           console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
             console.log("Res : ", res);
             if(res.status===200){
               
                 setProfileImage(newFileName);
                 var timestamp = new Date().getTime();     
                 var tprofile = document.getElementById("profile");
                 var imgsrc="";
                 imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                 tprofile.src=imgsrc;
                 //console.log("tprofle : ", tprofile.src);

             }
        }).catch((error)=>{
            console.log("error1:", error);
         // setDispMsg(error.message);
         // hideLoader();
       })
    }catch(error){
      console.log("error1:", error);
    }

  }*/

  /* const handleSelectFile = async(event)=>{
     event.preventDefault();
    // showLoader();
     //console.log("Event : ", event.target.files[0]);
     try{
     if (event.target.files && event.target.files.length > 0) {
       console.log("file selected");
       //const timestamp=new Date().getTime();
       //const newFileName=fileName+"-"+timestamp+".jpeg";
       const newFileName=fileName+".jpeg";
       console.log("New file name : ", newFileName);
       //const session = await Auth.currentSession();
       //const access_token=session.idToken.jwtToken;
       const bucketName=config.s3.bucketName;
       console.log("bucket name : ", bucketName);
       var file = event.target.files[0];
       var blob = file.slice(0, file.size, 'image/jpeg'); 
       const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
       console.log("New file : ", newFile);
       await axios.get(`${config.api.invokeUrl}/registration/profile_avatar/presigned?fileName=${newFileName}&bucketName=${bucketName}`,{
            headers:{
                //Authorization: access_token,
                'x-api-key': config.api.key
            }
        }).then(async(response)=>{
            console.log("Response : ", response);
           const res=await fetch(response.data.uploadURL, { method: 'PUT',
            headers:{
                "Content-Type":"image/jpeg"
              },body: newFile});
              console.log("Res : ", res);
              if(res.status===200){
                
                  setProfileImage(newFileName);
                  var timestamp = new Date().getTime();     
                  var tprofile = document.getElementById("profile");
                  var imgsrc="";
                  imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                  //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                  tprofile.src=imgsrc;
                  //console.log("tprofle : ", tprofile.src);

              }
         }).catch((error)=>{
             //console.log("error1:", error);
          // setDispMsg(error.message);
    //       hideLoader();
        })
     }
    }catch(error){
        //console.log("Error : ", error);
      //setDispMsg(error.message);
  //    hideLoader();
    }
  //  hideLoader();
   }
*/
   const handleImageError=(e)=>{
    // console.log("Event : ", e);
    e.target.src = "https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg"


   }


  /* const onSelectFile = async(event) => {
     const currentURL=config.s3.imageURL+profileImage;
     //console.log("Current URL : ", currentURL);
     //console.log("Event : ", event);
    if (event.target.files && event.target.files.length > 0) {
       // props.setSelectedImageFile(event.target.files[0]);
       //console.log("Files : ", event.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", async() => {
        setImage(reader.result);
        //console.log("Image : ", reader.result);
        const timestamp=new Date().getTime();
        const newFileName=fileName+"-"+timestamp+".jpeg";
        //console.log("filename : ", newFileName);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const bucketName=config.s3.bucketName;
        var file = event.target.files[0];
        var blob = file.slice(0, file.size, 'image/jpeg'); 
        const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
       // console.log("after update : ", newFile);
      await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${fileName}&bucketName=${bucketName}`,{
      //await axios.get(`${config.api.invokeUrl}/presigned/uploader?fileName=${fileName}&bucketName=${bucketName}`,{
          headers:{
              Authorization: access_token,
              'x-api-key': config.api.key
          }
      })
       .then(async(response)=>{
         //  console.log("response : ", response);
         //  console.log("selected file : ", newFile);
          await fetch(response.data.uploadURL, {
          method: 'PUT',
          headers:{
              "Content-Type":"image/jpeg"
          },
          body: newFile
          
        });
       // setUser({...user,profileImage:fileName});
        setDispMsg("Image Successfully Uploaded!");
     
       }).catch((error)=>{
        // console.log("Error : ", error);
          setDispMsg("Error Occured - ", error.message);
       })*/
        
      
        /*const tfileName= await AddImagetoS3(event.target.files[0], fileName);
        console.log("tfileName : ",tfileName);
        await props.setUser({...user,
            profileImage:tfileName
        });*/
        //console.log("selected File : ", selectedFile);
    //  });
     /* const tfileName= await AddImagetoS3(event.target.files[0], fileName);
      var tprofile = document.getElementById("profile");     
      tprofile.src=`${config.s3.imageURL}${tfileName}`;
      console.log("tfileName : ",tfileName);*/
      /*await props.setUser({...user,
          profileImage:tfileName
      });*/

     // fetchMyDetails();
     // window.location.reload();
 /*   }
  };
  */
    return (
      <div style={{textAlign:"center"}}> 
      
       {profileImage ? <img id="profile" src={profileImage} 
            onError={handleImageError}
            alt="Profile" style={{width:"8rem", height:"8rem", borderRadius:"50%"}} />
       :null}
      </div>
      
    )

}