import React, {useEffect, useContext} from 'react';
import { Card, CardBody } from 'reactstrap';
import logo from '../assets/vjdepth-logo.png';
import { ProductContext } from '../ProductProvider';

export default function WelcomeDash() {
  const { loggedUser, validateLogin} = useContext(ProductContext);

  useEffect(()=>{
    if(!loggedUser.isAuthenticated){
      validateLogin();
    //history.push(Routes.DashboardOverview.path);
    }
  },[loggedUser])

  return (
    <div className='container text-center'>
        <Card>
            <CardBody>

               

                <h1 className='text-info' style={{fontFamily:'sans-serif'}}>VJDEPTH </h1>
                <h3 className='text-info' style={{fontFamily:'sans-serif'}}>Student's Corner </h3>

                <img src={logo} alt='logo' />

            
            </CardBody>
        </Card>
        
    </div>
  )
}
